import {
  getColumnDisplayName,
  sentenceCase,
  sentenceCaseShort,
} from "../utils";
import { useState, useEffect, useCallback } from "react";
import { IconButton } from "../elements/IconButton";
import StarsIcon from "../svgs/Stars";
import backend_services from "src/services/backend_service";
import { toast } from "react-toastify";
import { MultiValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import { useAuth } from "src/context/AuthContext";
import { useParams } from "react-router-dom";
import { SimpleSpinner } from "../elements/Spinner";
import useDefaultTable, { Column } from "src/hooks/useChatTable";
import useChatDetails from "src/hooks/useChatDetails";
import { twMerge } from "tailwind-merge";
import { KurationTool } from "src/models/tools";
import { ColumnsTextArea } from "src/pages/filters/ColumnsTextArea";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { useStateContext } from "src/context/StateContext";
import { useSearchParams } from "react-router-dom";
import {
  FilterFields,
  FilterFormField,
  getUpdatedForm,
} from "../preCuratedLists/FilterFields";
import PlusIcon from "../svgs/Plus";
import { Company, FreeDict } from "src/models/data";
import { parseFilterForm } from "src/pages/filters/filterUtils";

type OptionType = {
  value: string;
  label: string;
};

export function KurationColumnForm({
  selectedTool,
  columnsFallback,
  onSuccess,
  preventColumnCreation,
  companies,
}: {
  selectedTool: KurationTool;
  columnsFallback: Column[];
  onSuccess: ({
    tool,
    args,
    conditions,
  }: {
    tool: KurationTool;
    args: FreeDict;
    conditions: FreeDict[];
  }) => void;
  preventColumnCreation?: boolean;
  companies?: Company[];
}) {
  const { chatId } = useParams();
  const { chatDetails } = useChatDetails(chatId ?? null);

  const tableId = chatDetails?.table_id;

  const { data: defaultTable } = useDefaultTable(tableId);
  const columns = defaultTable?.column_list || columnsFallback;
  const [conditionalColumn, setConditionalColumn] = useState(false);
  const [columnForm, setColumnForm] = useState<{
    [key: string]: string | null;
  }>(selectedTool.fields.reduce((acc, f) => ({ ...acc, [f.id]: null }), {}));

  const [errors, setErrors] = useState<string[]>([]);
  const { user } = useAuth();

  const [addColumnLoading, setAddColumnLoading] = useState(false);
  const [filterForm, setFilterForm] = useState<FilterFormField[]>([]);

  async function add_column(args: {
    [key: string]: string | null | Array<FreeDict>;
  }) {
    if (!user || !selectedTool) {
      return;
    }

    const newErrors: string[] = [];

    selectedTool.fields.forEach((f) => {
      const fieldValue = args[f.id]; // Access the field value using its `id` in args

      // If the field is required (optional is false) and missing a value, add it to errors
      if (!fieldValue && !f.optional) {
        newErrors.push(f.id);
      }
    });

    // Update error state
    setErrors(Array.from(new Set(newErrors)));

    if (newErrors.length > 0) return;

    const columnConditions = parseFilterForm(
      filterForm,
      defaultTable?.column_list || [],
    );
    setAddColumnLoading(true);
    if (preventColumnCreation) {
      setAddColumnLoading(false);
      onSuccess({ tool: selectedTool, args, conditions: columnConditions });
      return;
    }
    await backend_services
      .fetchProtectedData(
        `/chat/${chatId}/column/${selectedTool.id}`,
        user?.getIdToken(),
        undefined,
        "POST",
        { args, column_conditions: columnConditions },
      )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("invalid result");
        }

        toast.success("New column added at the end of table.");
        onSuccess({ tool: selectedTool, args, conditions: columnConditions });
        return res.json();
      })
      .catch((err) => {
        toast.error("Failed to create column. Please try again.");
        setAddColumnLoading(false);
      });
    setAddColumnLoading(false);
  }

  const handleSubmit = () => {
    // Create a new object where unfilled fields are set to an empty string
    const updatedArgs = Object.fromEntries(
      Object.entries(columnForm).map(([key, value]) => [key, value ?? ""]),
    );

    add_column(updatedArgs);
  };

  if (addColumnLoading) {
    return (
      <div className="flex h-full items-center justify-center gap-2 py-5">
        <SimpleSpinner radius={20} overrideClasses="border-black" />
        loading..
      </div>
    );
  }
  return (
    <div>
      <div className="mt-6 flex flex-col gap-4">
        <KurationColumnFormFields
          selectedTool={selectedTool}
          columnForm={columnForm}
          setColumnForm={setColumnForm}
          errors={errors}
          setErrors={setErrors}
          columns={columns}
          companies={companies}
        />
        <div className="mt-12">
          <ColumnConditionAdder
            key={"conditions"}
            columns={defaultTable?.column_list || []}
            conditionalColumn={conditionalColumn}
            filterForm={filterForm}
            setConditionalColumn={setConditionalColumn}
            setFilterForm={setFilterForm}
          />
        </div>
      </div>

      <IconButton
        overrideClasses="mt-4 h-9 gap-1.5 border-purple text-white  md:h-[42px]  "
        onClick={handleSubmit}
        variant="fill"
        icon={<StarsIcon className="h-4 w-4 md:h-6 md:w-6" fill="white" />}
        text={
          <div className="min-w-max text-sm  md:text-lg ">Create Column</div>
        }
      />
    </div>
  );
}
export const KurationColumnFormFields = ({
  selectedTool,
  columnForm,
  setColumnForm,
  errors,
  setErrors,
  columns,
  companies,
}: {
  selectedTool: KurationTool;
  columnForm: {
    [key: string]: string | null;
  };
  setColumnForm: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string | null;
    }>
  >;
  errors: string[];
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  columns: Column[];
  companies?: Company[];
}) => {
  const [originalId, setOriginalId] = useState<string | null>(null);
  const [jsonKeyOptions, setJsonKeyOptions] = useState<string[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const fetchJsonKeyOptions = useCallback(() => {
    if (!companies) {
      console.warn("Missing required parameters for fetching JSON keys");
      return;
    }

    const toastId = toast.loading("Fetching JSON keys...");
    setIsFetching(true);

    try {
      console.log("Custom Column ID:", originalId);

      const matchingItems = companies.flatMap((record) =>
        record.filter(
          (item: { custom_column_id: string }) =>
            item.custom_column_id === originalId,
        ),
      );

      if (matchingItems.length > 0) {
        const keys = matchingItems.flatMap((item) => {
          try {
            const value = item?.value;

            // If the value is a JSON string, parse it
            const jsonValue =
              typeof value === "string" ? JSON.parse(value) : value;

            // Extract keys from the JSON object, including nested keys
            const extractKeys = (obj: Record<string, any>): string[] => {
              let keys: string[] = [];
              for (const key in obj) {
                // Skip numeric keys (e.g., array indices)
                if (!isNaN(Number(key))) continue;

                keys.push(key);
                if (typeof obj[key] === "object" && obj[key] !== null) {
                  keys = keys.concat(extractKeys(obj[key])); // Recursively collect nested keys
                }
              }
              return keys;
            };

            return extractKeys(jsonValue);
          } catch (error) {
            console.warn("Failed to parse value as JSON:", item.value, error);
            return [];
          }
        });

        // Flatten and deduplicate all keys across all items
        const uniqueKeys = Array.from(new Set(keys));

        if (uniqueKeys.length > 0) {
          setJsonKeyOptions(uniqueKeys);
          toast.update(toastId, {
            render: "JSON keys fetched successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        } else {
          setJsonKeyOptions([]);
          toast.update(toastId, {
            render: "No JSON keys found in the selected row",
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
        }
      } else {
        console.error("No matching custom_column_id found for:", originalId);
        setJsonKeyOptions([]);
        toast.update(toastId, {
          render: "Please select a row containing JSON",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error processing JSON keys:", error);
      setJsonKeyOptions([]);
      toast.update(toastId, {
        render: "Error processing JSON keys",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    } finally {
      setIsFetching(false);
    }
  }, [companies, originalId]);

  const handleColumnChange = (selectedValue: string, fId: string) => {
    setErrors((errs) => errs.filter((err) => err !== fId));
    setColumnForm((columnForm) => ({
      ...columnForm,
      [fId]: selectedValue,
    }));

    if (fId === "key_column") {
      const selectedColumn = columns.find(
        (col) => col.data_field === selectedValue,
      );

      if (selectedColumn) {
        setOriginalId(selectedColumn.id);
      }
    }
  };

  useEffect(() => {
    if (originalId) {
      fetchJsonKeyOptions();
    }
  }, [originalId, fetchJsonKeyOptions]);

  // Extract relevant fields for initialization in useEffect (this is needed if the user sends all the default fields)
  useEffect(() => {
    selectedTool.fields.forEach((f) => {
      if (f.fieldType === "tagSelect") {
        const optionList: OptionType[] = f.options.map((value) => ({
          value,
          label: value,
        }));

        const initialValue = optionList
          .map((option) => option.value)
          .join(", ");
        setColumnForm((columnForm) => ({
          ...columnForm,
          [f.id]: initialValue,
        }));

        setErrors((errs) => errs.filter((e) => e !== f.id));
      }
    });
  }, [selectedTool.fields, setColumnForm, setErrors]);

  return (
    <>
      {selectedTool.fields.map((f) => {
        if (!Object.hasOwn(columnForm, f.id)) {
          setColumnForm((columnForm) => ({
            ...columnForm,
            [f.id]: null,
          }));
        }
        if (
          selectedTool.id === "key_value_extractor" &&
          f.fieldType === "keySelect"
        ) {
          const id = `${selectedTool.id}-${f.label}`;
          // For json_key_select, use the fetched options instead of f.options
          const options =
            f.id === "json_key_select"
              ? jsonKeyOptions
              : f.options === "__COLUMN__"
                ? columns
                : f.options;

          // Check if it's the JSON key select field and hide it if no options are available
          const isJsonKeySelect = f.id === "json_key_select";
          const hasOptions = options.length > 0;

          if (isJsonKeySelect && !hasOptions) {
            return null;
          }

          return (
            <div className="flex flex-col gap-2" key={f.id}>
              <label
                htmlFor={id}
                className="block pl-1 font-medium text-gray-900"
              >
                {f.label}
              </label>
              <select
                id={id}
                defaultValue={""}
                onChange={(e) => handleColumnChange(e.target.value, f.id)}
                className={twMerge(
                  "block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500",
                  errors.includes(f.id) ? "border-red-600" : "",
                )}
              >
                <option value={""}>Not selected</option>
                {options.map((o) => {
                  const currOption =
                    typeof o === "string"
                      ? { id: o, display: o, originalId: o }
                      : {
                          id: o.data_field,
                          originalId: o.id,
                          display: getColumnDisplayName(o),
                        };

                  return (
                    <option value={currOption.id} key={currOption.id}>
                      {sentenceCase(currOption.display, "_")}
                    </option>
                  );
                })}
              </select>

              {isJsonKeySelect && isFetching && (
                <p className="text-sm text-gray-500">Fetching options...</p>
              )}
            </div>
          );
        }

        if (f.fieldType === "textarea") {
          const id = `${selectedTool.id}-${f.label}`;
          return (
            <div className={`flex flex-col gap-2`} key={f.id}>
              <label className="pl-1" htmlFor={id}>
                {f.label}
              </label>
              <ColumnsTextArea
                id={id}
                placeholder={f.placeholder}
                overrideClasses={`${errors.includes(f.id) ? "border border-red-600 hover:border-red-800" : ""} `}
                val={columnForm[f.id] || ""}
                setVal={(v) => {
                  if (errors.includes(f.id)) {
                    setErrors((errs) => [...errors.filter((e) => e !== f.id)]);
                  }
                  setColumnForm((columForm) => ({
                    ...columForm,
                    [f.id]: v,
                  }));
                }}
                columns={columns}
              />
            </div>
          );
        } else if (f.fieldType === "keySelect") {
          const id = `${selectedTool.id}-${f.label}`;
          const options: Column[] | string[] =
            f.options === "__COLUMN__" ? columns : f.options;
          return (
            <div className="flex flex-col gap-2" key={f.id}>
              <label
                htmlFor={id}
                className="block pl-1 font-medium text-gray-900 "
              >
                {f.label}
              </label>
              <select
                defaultValue={undefined}
                onChange={(e) => {
                  setErrors((errs) => [...errors.filter((e) => e !== f.id)]);
                  setColumnForm((columForm) => ({
                    ...columForm,
                    [f.id]: e.target.value,
                  }));
                }}
                id="countries"
                className={twMerge(
                  `block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 `,
                  `${errors.includes(f.id) ? "border border-red-600" : ""} `,
                )}
              >
                <option value={""}>Not selected</option>
                {options.map((o) => {
                  const currOption =
                    typeof o === "string"
                      ? { id: o, display: o }
                      : {
                          id: o.data_field,
                          display: getColumnDisplayName(o),
                        };
                  return (
                    <option value={currOption.id} key={currOption.id}>
                      {sentenceCaseShort(currOption.display, "_", 80)}
                    </option>
                  );
                })}
              </select>
            </div>
          );
        } else if (f.fieldType === "tagSelect") {
          const id = `${selectedTool.id}-${f.label}`;

          const optionList: OptionType[] = f.options.map((value) => ({
            value,
            label: value,
          }));

          return (
            <div className="flex flex-col gap-2" key={f.id}>
              <label
                htmlFor={id}
                className="block pl-1 font-medium text-gray-900 "
              >
                {f.label}
              </label>
              <div>
                <CreatableSelect
                  defaultValue={optionList.slice(0, 9)}
                  isMulti
                  name="colors"
                  options={optionList}
                  className={twMerge(
                    `w-[602.41px]`,
                    `${errors.includes(f.id) ? "border border-red-600" : ""} `,
                  )}
                  classNamePrefix="select"
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      width: "602.41px",
                      fontFamily: "",
                    }),
                  }}
                  onChange={(options: MultiValue<OptionType>) => {
                    const values = options.map((option) => option.value);
                    const result = values.join(", ");
                    console.log(result);

                    setErrors((errs) => errs.filter((e) => e !== f.id));
                    setColumnForm((columForm) => ({
                      ...columForm,
                      [f.id]: result,
                    }));
                  }}
                />
              </div>
            </div>
          );
        }
        return <>x</>;
      })}
    </>
  );
};
export function ColumnConditionAdder({
  conditionalColumn,
  setConditionalColumn,
  columns,
  filterForm,
  setFilterForm,
}: {
  columns: Column[];
  conditionalColumn: boolean;
  setConditionalColumn: React.Dispatch<React.SetStateAction<boolean>>;
  filterForm: FilterFormField[];
  setFilterForm: React.Dispatch<React.SetStateAction<FilterFormField[]>>;
}) {
  const [randId] = useState(Math.random().toString());

  return (
    <div>
      <div className={`flex gap-2`} key={randId}>
        <input
          checked={conditionalColumn}
          onChange={(e) => {
            setConditionalColumn(e.target.checked);
          }}
          id={randId}
          type="checkbox"
          className=""
        />
        <label
          className={twMerge(
            "pl-1 text-gray-500 transition-all duration-300 hover:text-gray-800",
            `${conditionalColumn ? "text-gray-800" : ""}`,
          )}
          htmlFor={randId}
        >
          Run conditionally, only where specific conditions are true, e.g.
          "employees"=="10"
        </label>
      </div>
      {conditionalColumn && columns && (
        <ColumnConditions
          columns={columns}
          filterForm={filterForm}
          setFilterForm={setFilterForm}
          runFilter={false}
        />
      )}
    </div>
  );
}

export function ColumnConditions({
  columns,
  filterForm,
  setFilterForm,
  runFilter,
}: {
  columns: Column[];
  filterForm: FilterFormField[];
  setFilterForm: React.Dispatch<React.SetStateAction<FilterFormField[]>>;
  runFilter: boolean;
}) {
  const [, setSearchParams] = useSearchParams();
  const { remainingRows } = useStateContext();

  useEffect(() => {
    console.log("Remaining rows updated:......", remainingRows);
  }, [remainingRows]);

  const filterOptions: Array<{ id: FilterFormField["type"]; name: string }> = [
    { id: "equals", name: "Equals" },
    { id: "notEquals", name: "Not Equals" },
    { id: "startsWith", name: "Starts With" },
    { id: "contains", name: "Contains" },
    { id: "exists", name: "Exists" },
    { id: "greaterThan", name: "Greater Than" },
    { id: "lessThan", name: "Less Than" },
  ];
  // console.log(filterForm);

  function handleFormUpdate(
    f: FilterFormField,
    k: keyof FilterFormField,
    v: string | number | boolean,
  ) {
    setFilterForm((prev) =>
      getUpdatedForm({
        prev,
        updatedField: f,
        k,
        v: k === "value" && f.type === "exists" ? v === "true" : v,
      }),
    );
  }
  function handleFilterRemove(f: FilterFormField) {
    setFilterForm((prev) => {
      const updatedFilters = [...prev.filter((ff) => ff.id !== f.id)];
      submit(updatedFilters);
      return updatedFilters;
    });
  }
  async function submit(filterForm: FilterFormField[]) {
    const filterToSend: { [key: string]: any }[] = [];
    filterForm.forEach((f) => {
      if (f.type === "greaterThan" || f.type === "lessThan") {
        f.value = parseInt(f.value as string);
      }
      if (f.type === "exists") {
        f.value = f.value === true || f.value === "true";
      }
      filterToSend.push(f);
    });
    if (false) {
      // console.log(searchParams);
    }
    const encoded = encodeURIComponent(JSON.stringify(filterToSend));
    setSearchParams((prev) => {
      prev.set("filter", encoded);
      return prev;
    });
  }

  return (
    <div>
      <FilterFields
        cols={columns.map((c) => ({
          name: getColumnDisplayName(c),
          id: c.data_field,
        }))}
        filterForm={filterForm}
        handleFormUpdate={handleFormUpdate}
        handleFilterRemove={handleFilterRemove}
        filterOptions={filterOptions}
      />
      <div className="flex items-center gap-4">
        <IconButton
          overrideClasses="max-w-max"
          icon={<PlusIcon className="fill-purpleHover" />}
          text="Add filter"
          variant="outline"
          onClick={() => {
            setFilterForm((prev) => [
              ...prev,
              {
                id: Math.random().toString(),
                name: "",
                key: "",
                type: "",
                value: "",
              },
            ]);
          }}
        />
        {filterForm.length > 0 && remainingRows !== null && (
          <span className="text-purple">{remainingRows} rows remaining</span>
        )}
      </div>

      {filterForm.length >= 1 && runFilter === true && (
        <div className="mt-12 flex justify-center">
          <IconButton
            overrideClasses="max-w-max"
            icon={<FunnelIcon width={20} className="fill-white" />}
            text={"Run filter"}
            variant="fill"
            onClick={() => {
              submit(filterForm);
            }}
          />
        </div>
      )}
    </div>
  );
}
