import CoinIcon from "src/components/svgs/Coin";
import StarsIcon from "src/components/svgs/Stars";
import { useStateContext } from "src/context/StateContext";

export const FilterCard = ({
  name,
  iconUrl,
  description,
  cost,
}: {
  name: string;
  iconUrl?: string;
  description?: string;
  cost?: number;
}) => {
  const icon = iconUrl ? (
    <img className="h-[19.63px] w-[21.02px]" src={iconUrl} alt={name} />
  ) : (
    <div className="relative h-6 w-6">
      <StarsIcon fill="#e08588" />
    </div>
  );
  const bottomRow = name;
  return (
    <EnrichmentLibraryCard
      icon={icon}
      bottomRow={bottomRow}
      description={description}
      cost={cost}
    />
  );
};
export const EnrichmentLibraryCard = ({
  icon,
  bottomRow,
  description,
  cost,
}: {
  icon: React.ReactNode;
  bottomRow: string;
  description?: string;
  cost?: number;
}) => {
  const { chatFullScreen } = useStateContext();
  return (
    <div className="flex h-[130px] max-w-[400px] cursor-pointer flex-col justify-between gap-6 rounded-md border-b-4 border-neutral-100 bg-neutral-100 px-3 py-4 transition-all duration-300 hover:border-purple active:scale-90">
      <div className="flex w-full flex-col">
        <div className="flex items-center justify-between">
          <div className="pb-2">{icon}</div>
          <div className="flex items-center gap-1 rounded-md bg-[#E1FFDE] px-2 py-[2px]">
            <p className="text-[#32CB61]">{cost === 0 ? "Free" : cost}</p>

            <CoinIcon />
          </div>
        </div>
        <div>
          <div className="text-xl text-neutral-900">{bottomRow}</div>
          {chatFullScreen && (
            <div className="pt-1 text-xs text-neutral-900">{description}</div>
          )}
        </div>
      </div>
    </div>
  );
};
