import { InitialListItem } from "src/models/InitialList";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CSVFileUploader from "./CSVFileUploader";
import UploadIcon from "../svgs/Upload";
import ActionIcon from "../svgs/Action";

export function HomeLists() {
  const items: InitialListItem[] = [
    {
      _id: "6",
      name: "csvFileUploader",
      description: "Upload CSV file",
      subtitle: "Import and curate existing lists",
      icon: <UploadIcon />,
    },
    {
      _id: "7",
      name: "startFromScratch",
      description: "Start from Scratch",
      subtitle: "Create a List",
      icon: <ActionIcon />,
    },
  ];

  return (
    <div className="grid h-full grid-rows-[min-content,1fr] gap-10 px-5">
      <div>
        <p className="text-4xl font-normal text-neutral-900">Get Started</p>
        <p className="text-lg font-light text-neutral-600">
        Upload your existing list or build one from scratch with our best sources!
        </p>
      </div>
      <div className="flex flex-wrap justify-start gap-4">
        {items.map((item) => (
          <InitialCard key={item._id} item={item} />
        ))}
      </div>
    </div>
  );
}

const InitialCard = ({ item }: { item: InitialListItem }) => {
  const [showQueryBox, setShowQueryBox] = useState(false);
  const queryBoxRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  function handleClick() {
    if (item.name === "startFromScratch") {
      navigate("/list-builder");
    } else {
      setShowQueryBox(true);
    }
  }

  function handleClose() {
    setShowQueryBox(false);
  }

  

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        queryBoxRef.current &&
        !queryBoxRef.current.contains(event.target as Node)
      ) {
        handleClose();
      }
    }

    if (showQueryBox) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showQueryBox]);

  return (
    <div>
      <button
        className="flex flex-col items-center justify-center gap-4 rounded-lg border-b-4 border-gray-200 bg-gray-200 p-4 transition-all hover:shadow-lg"
        onClick={handleClick}
        style={{ width: "300px", height: "180px" }}
      >
        <div className="flex flex-col items-center gap-2">
          <div className="flex h-16 w-16 items-center justify-center rounded-full bg-white p-2">
            {item.name === "startFromScratch" ? (
              <img
                src="https://cdn.prod.website-files.com/64acc1670486f7df09be2423/67335ee61378e50e6904117c_Manually.svg"
                alt="CSV Uploader"
                className="h-8 w-8 rounded-full object-cover"
              />
            ) : (
              <div className="flex h-full w-full items-center justify-center overflow-hidden rounded-full">
                {item.icon}
              </div>
            )}
          </div>
          <div className="flex flex-col items-center justify-center text-center">
            <div className="text-lg font-normal text-gray-900">
              {item.description}
            </div>
            <p className="card-subtitle"></p>
            <div className="text-sm font-light text-gray-600">
              {item.subtitle}
            </div>
          </div>
        </div>
      </button>

      {showQueryBox && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div
            ref={queryBoxRef}
            className="relative rounded-lg bg-white p-6 shadow-lg"
          >
            {item.name === "csvFileUploader" && (
              <CSVFileUploader item={item} handleClose={handleClose} />
            )}
            <button
              className="absolute right-2 top-2 text-gray-600 hover:text-gray-900"
              onClick={handleClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
