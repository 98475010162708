import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getColumnDisplayName,
  getCompanyNameColumn,
  getDomainNameColumn,
  getUniqueColumns,
  reorderColumns,
  sentenceCase,
} from "../utils";
import { Company, Field, ResearchPlanItem } from "../../models/data";
import { twMerge } from "tailwind-merge";
import { FreeDict } from "../../models/data";
import QuestionMarkIcon from "src/components/svgs/QuestionMark";
import FullScreenIcon from "src/components/svgs/FullScreen";
import {
  Column,
  CustomToolColumn,
  InitialListName,
  KurationToolColumn,
  TableState,
} from "src/hooks/useChatTable";
import { toast } from "react-toastify";
import backend_services from "src/services/backend_service";
import { useAuth } from "src/context/AuthContext";
import {
  AltColumn,
  getColumnIdsFromColumnList,
  ToolColumnHeader,
  getOriginalOrAltItem,
  CellText,
  SourceTooltip,
  SkippedCell,
  LoadingCell,
} from "./tableUtils";
import { useShowCostQuery } from "src/hooks/useSearchQuery";
import { useStateContext } from "src/context/StateContext";
import { IconButton } from "../elements/IconButton";
import { useListFilterQuery } from "src/hooks/useSearchQuery";
import PlusIcon from "../svgs/Plus";
import PinIcon from "src/components/svgs/PinIcon";
import { SimpleSpinner } from "../elements/Spinner";
import CustomModal from "../elements/CustomModel";
import LinkedinJobScrapper from "../initialLists/LinkedinJobScrapper";
import IndeedJobScraper from "../initialLists/IndeedJobScraper";
import SalesNavSearchList from "../initialLists/SalesNavSearchList";

// Global variable for pinned column
let globalPinnedColumn: number | null = null;

export const CompanyListTable = memo(
  ({
    companies,
    height = "full",
    compact = false,
    startIndex,
    columnDetails = [],
    setAddColumnModalOpen,
    setAddRowsModalOpen,
    companyLoadingColumns,
    alternativeColumns = [],
    columnList,
    columnFilters,
    rowIds,
    onColumnRun,
    table_state,
    input,
    initial_list_name,
  }: {
    companies: Company[];
    height?: "small" | "full";
    compact?: boolean;
    startIndex: number;
    columnDetails?: Array<ResearchPlanItem>;
    setAddColumnModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    setAddRowsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    companyLoadingColumns?: string[][];
    alternativeColumns?: AltColumn[];
    columnList?: Array<Column>;
    columnFilters?: any[];
    rowIds?: string[];
    onColumnRun?: (colId: string, pause: boolean) => void;
    table_state?: TableState;
    input?: FreeDict;
    initial_list_name?: string;
  }) => {
    // const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const { setSelectedRows, selectedRows } = useStateContext();
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [pinnedColumn, setPinnedColumn] = useState<number | null>(null); // Change to store a single pinned column
    const [columnWidths, setColumnWidths] = useState<string[]>(
      Array(columnList?.length).fill("200px"),
    ); // Initialize with default widths

    // Set pinnedColumn from globalPinnedColumn on mount
    useEffect(() => {
      if (globalPinnedColumn !== null) {
        setPinnedColumn(globalPinnedColumn);
      }
    }, []);

    const togglePinColumn = (index: number) => {
      setPinnedColumn((prev) => {
        const newPinnedColumn = prev === index ? null : index; // Pin the new column or unpin if it's already pinned
        globalPinnedColumn = newPinnedColumn; // Update the global variable
        return newPinnedColumn;
      });
    };

    const { user } = useAuth();
    const { chatId } = useParams();
    const navigate = useNavigate();

    const columns =
      (columnList && getColumnIdsFromColumnList(columnList)) ||
      getUniqueColumns(companies, {
        ignoreCustomColumns: true,
      });

    const visibleToolColumns = columnList?.filter(
      (c) =>
        (c.type === "tool_column" || c.type === "custom_tool_column") &&
        !c.hidden,
    ) as (KurationToolColumn | CustomToolColumn)[];
    const userAddedColumnIds = visibleToolColumns?.map((c) => c.id);
    const localColumns = reorderColumns(
      columns
        .filter((l) => !userAddedColumnIds?.includes(l))
        .filter(
          (c) =>
            !alternativeColumns.find((a) => {
              return a.original === c && columns.includes(a.alternative);
            }),
        ),
    );

    const localData = [...companies];

    localData.forEach((company) =>
      company.forEach((field) => {
        field.data_field = field.data_field.toLowerCase();
      }),
    );

    const companyNameColumn = getCompanyNameColumn(localColumns);
    const domainColumn = getDomainNameColumn(localColumns);
    const listFilter = useListFilterQuery();
    const [loadingColRuns, setLoadingColRuns] = useState<string[]>([]);

    async function colRunner(
      col: KurationToolColumn | CustomToolColumn,
      count: number,
      pause: boolean,
      error_only: boolean,
    ) {
      if (!user) {
        return;
      }
      setLoadingColRuns((prev) => [...prev, col.id]);
      await backend_services
        .fetchProtectedData(
          `/chat/${chatId}/column/${col.id}/${pause === false ? "run" : "pause"}?limit=${count}&error_only=${error_only}`,
          user?.getIdToken(),
          undefined,
          "POST",
          { filters: listFilter },
        )
        .then((res) => {
          if (res.status === 402) {
            toast.warn(
              "You ran out of credits. Please subscribe to a plan to continue.",
            );
            setTimeout(() => {
              navigate("/profile");
            }, 3000);
            return;
          }
          if (res.status !== 200) {
            throw new Error("invalid result");
          }
          onColumnRun && onColumnRun(col.id, pause);
          return res.json();
        })
        .catch((err) => {
          if (pause) {
            toast.error("Failed to pause column. Please try again.");
          } else {
            toast.error("Failed to run column. Please try again.");
          }
        })
        .finally(() => {
          setLoadingColRuns((prev) => prev.filter((id) => id !== col.id));
        });
      if (false) {
        // console.log(column_add_result);
      }
    }

    const escFunction = useCallback(
      (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          if (isFullscreen) {
            setIsFullscreen(false);
          }
        }
      },
      [isFullscreen],
    );

    useEffect(() => {
      document.addEventListener("keydown", escFunction, false);
      return () => {
        document.removeEventListener("keydown", escFunction, false);
      };
    }, [escFunction]);
    console.log("table_state", table_state);
    console.log("input", input);
    console.log("name", initial_list_name);

    const finalColumns = localColumns;
    const [retryDialougeOpen, setRetryDialougeOpen] = useState(false);
    async function updateCell({
      rowId,
      colId,
      newValue,
      onSuccess,
    }: {
      rowId: string;
      colId: string;
      newValue: string;
      onSuccess: () => void;
    }) {
      if (!user) {
        return;
      }
      const cell_update_result = await backend_services
        .fetchProtectedData(
          `/chat/${chatId}/table/update`,
          user?.getIdToken(),
          undefined,
          "POST",
          {
            row_id: rowId,
            col_id: colId,
            new_value: newValue,
          },
        )
        .then((res) => {
          if (res.status !== 200) {
            throw new Error("invalid result");
          }
          onSuccess();
          return res.json();
        })
        .catch((err) => {
          if (err) {
            toast.error("Failed to update cell. Please try again.");
          }
        });
      if (process.env.NODE_ENV === "development") {
        console.log(cell_update_result);
      }
    }

    const retry = () => {
      if (initial_list_name) {
        setRetryDialougeOpen(true);
      }
    };

    const handleClose = () => {
      setRetryDialougeOpen(false);
    };

    if (table_state === TableState.LOADING) {
      return (
        <div className="flex flex-col items-center justify-center">
          <SimpleSpinner radius={40} overrideClasses="border-primary" />
          <h1 className="mt-4 text-center text-2xl">
            Your request is being processed. This may take a few moments. You
            will be notified via email upon completion.
          </h1>
        </div>
      );
    }

    if (table_state === TableState.ERROR) {
      return (
        <div className="flex flex-col items-center justify-center">
          <h1 className="mt-4 text-center text-2xl text-red-400">
            An error occurred while processing your request. Please try again.
          </h1>
          <button
            onClick={retry}
            className="mt-4 rounded-md bg-red-300 px-4 py-2 hover:bg-red-400"
          >
            Retry
          </button>
          {retryDialougeOpen && (
            <CustomModal
              isOpen={retryDialougeOpen}
              onClose={() => {
                setRetryDialougeOpen(false);
              }}
            >
              {
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {initial_list_name === InitialListName.LINKEDIN && (
                    <LinkedinJobScrapper
                      handleClose={handleClose}
                      numberOfJobs={input?.rows}
                      initialJobTitle={input?.title}
                      initialExperienceLevel={input?.experienceLevel}
                      initialJobType={input?.contractType}
                      initialOnsiteRemote={input?.workType}
                      initialLocation={input?.location}
                      initialPublishedAt={input?.publishedAt}
                      table_state={table_state}
                      chat_id={chatId}
                    />
                  )}

                  {initial_list_name === InitialListName.INDEED && (
                    <IndeedJobScraper
                      handleClose={handleClose}
                      initialSearchUrl={input?.searchUrl}
                      table_state={table_state}
                      chat_id={chatId}
                    />
                  )}

                  {initial_list_name === InitialListName.SALESNAV && (
                    <SalesNavSearchList
                      handleClose={handleClose}
                      initialSearchURL={input?.search_url}
                      initialLinkedinCookies={input?.linkedin_cookies}
                      table_state={table_state}
                      chat_id={chatId}
                    />
                  )}
                </div>
              }
            </CustomModal>
          )}
        </div>
      );
    }

    if (!setAddRowsModalOpen && (!finalColumns || finalColumns.length < 1)) {
      return <div>Main table updated with latest data.</div>;
    }
    if (setAddRowsModalOpen && (!finalColumns || finalColumns.length < 1)) {
      return (
        <div className="flex w-full flex-col-reverse items-center justify-center gap-2 p-3 ">
          <div className="text-center text-gray-600">
            <div>No companies yet.</div>
            <div>
              <div>
                Kuration is all about curating companies, add some to begin.
              </div>
              <div>You can add as many as you want.</div>
            </div>
          </div>
          <IconButton
            overrideClasses="max-w-max"
            icon={<PlusIcon className="fill-purpleHover" />}
            text={"Add new companies"}
            variant="outline"
            onClick={() => {
              setAddRowsModalOpen(true);
            }}
          />
        </div>
      );
    }

    // Assuming columnFilters is an array of objects with { column_id, status }
    const hiddenColumnIds = new Set(
      columnFilters
        ?.filter((filter) => filter.status) // Get only those with status true
        .map((filter) => filter.column_id.toLowerCase()), // Convert column IDs to lowercase
    );

    const handleMouseDown = (event: React.MouseEvent, index: number) => {
      const startX = event.clientX;

      const handleMouseMove = (moveEvent: MouseEvent) => {
        const newWidth =
          moveEvent.clientX - startX + parseInt(columnWidths[index], 10);
        setColumnWidths((prevWidths) => {
          const newWidths = [...prevWidths];
          newWidths[index] = newWidth > 50 ? `${newWidth}px` : "50px"; // Minimum width of 50px
          return newWidths;
        });
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

    const handleRowSelect = (rowIndex: number) => {
      setSelectedRows((prevSelectedRows: number[]) => {
        if (prevSelectedRows.includes(rowIndex)) {
          // If the row is already selected, unselect it (remove from the array)
          return prevSelectedRows.filter((index: number) => index !== rowIndex);
        } else {
          // If the row is not selected, select it (add to the array)
          return [...prevSelectedRows, rowIndex];
        }
      });
    };

    return (
      <>
        <div
          className={twMerge(
            `w-full rounded `,
            `${height === "small" ? "max-h-[29vh] overflow-auto" : ""}`,
            `${isFullscreen ? "max-w-screen fixed inset-0 z-50 max-h-screen overflow-auto bg-white " : ""}`,
          )}
        >
          <table
            className={twMerge(
              `relative max-w-full overflow-auto text-left text-sm font-light`,
            )}
          >
            <thead className="z-10 font-medium">
              <tr className="divide-x border-r border-grey-400">
                {!compact && (
                  <th
                    className="group sticky top-0 w-11 border-b border-t"
                    onClick={() => {
                      setIsFullscreen(!isFullscreen);
                    }}
                  >
                    <div className="cursor-pointer bg-bg-2 px-7 py-4">
                      <FullScreenIcon className="fill-grey-100 group-hover:fill-black" />
                    </div>
                  </th>
                )}
                {finalColumns.map((column, index) => {
                  const normalizedColumn = column.toLowerCase();
                  if (hiddenColumnIds.has(normalizedColumn)) {
                    return null; // Skip rendering this header
                  }

                  const columnDetail = columnDetails.find(
                    (d) => d.data_field.toLowerCase() === column,
                  );
                  const originalCol = columnList?.find(
                    (c) => c.id.toLowerCase() === column,
                  );

                  return (
                    <th
                      className={twMerge(
                        "group sticky -top-1 z-20 border-b border-l border-t p-0 transition-all duration-300 hover:bg-lightpurple",
                        `${pinnedColumn === index ? "left-0 z-30" : ""}`,
                      )}
                      key={index}
                      title={`${columnDetail?.Question || ""}\n\n${columnDetail?.Answer_Structure || ""}`}
                      onClick={() => togglePinColumn(index)}
                    >
                      <div className="relative flex h-full items-center justify-between truncate bg-bg-2 px-5 py-3 text-lg font-normal text-grey-100 transition-all duration-300 hover:bg-lightpurple">
                        <span
                          className="truncate"
                          style={{ width: columnWidths[index] }}
                        >
                          {originalCol
                            ? getColumnDisplayName(originalCol)
                            : sentenceCase(column, "_")}
                          <div
                            onMouseDown={(e) => handleMouseDown(e, index)}
                            className="resize-handle"
                          />
                        </span>

                        <span
                          className={twMerge(
                            "ml-2 pt-2 transition-opacity duration-300",
                            pinnedColumn === index
                              ? "opacity-100"
                              : "opacity-0 group-hover:opacity-100",
                          )}
                        >
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              togglePinColumn(index);
                            }}
                            title={pinnedColumn === index ? "Unpin" : "Pin"}
                          >
                            <PinIcon
                              color={
                                pinnedColumn === index ? "green" : "#F44336"
                              }
                              className="h-6 w-4 transition-colors duration-300"
                            />
                          </button>
                        </span>
                        {columnDetail ? (
                          <span className="inline-block cursor-help">
                            <QuestionMarkIcon className="h-4 w-4 fill-grey-100" />
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </th>
                  );
                })}
                {visibleToolColumns?.map((col, index) => {
                  const normalizedColumn = col.id.toLowerCase();
                  if (hiddenColumnIds.has(normalizedColumn)) {
                    return null; // Skip rendering this header
                  }

                  return (
                    <th
                      key={col.timestamp.toString()}
                      className={twMerge(
                        "group sticky -top-1 z-20 border-b border-l border-t bg-bg-2 p-0 transition-all duration-300 hover:bg-lightpurple",
                        `${pinnedColumn === index + finalColumns.length ? "left-0 z-30" : ""}`,
                      )}
                    >
                      <div className="flex items-center">
                        {/* Display the index (0-based) */}
                        <ToolColumnHeader
                          col={col}
                          colRunner={colRunner}
                          isRunLoading={loadingColRuns.includes(col.id)}
                        />
                        <span
                          className={twMerge(
                            "ml-2 pt-2 transition-opacity duration-300",
                            pinnedColumn === index + finalColumns.length
                              ? "opacity-100"
                              : "opacity-0 group-hover:opacity-100",
                          )}
                        >
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              togglePinColumn(index + finalColumns.length);
                            }}
                            title={
                              pinnedColumn === index + finalColumns.length
                                ? "Unpin"
                                : "Pin"
                            }
                          >
                            <PinIcon
                              color={
                                pinnedColumn === index + finalColumns.length
                                  ? "green"
                                  : "#F44336"
                              }
                              className="h-6 w-4 transition-colors duration-300"
                            />
                          </button>
                        </span>
                      </div>
                    </th>
                  );
                })}
                {setAddColumnModalOpen && (
                  <th
                    onClick={() => {
                      setAddColumnModalOpen(true);
                      // setFiltersOpen(true);
                    }}
                    className="group sticky top-0 border-b border-t"
                  >
                    <div className="relative z-50 flex max-w-[20ch] cursor-pointer items-center gap-1 truncate  bg-bg-2 px-5 py-3 text-lg font-normal text-grey-100 hover:bg-lightpurple md:max-w-[24cqw]">
                      + Add Column
                    </div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="divide-y border-r border-grey-400">
              {localData.map((row, rowIndex) => (
                <tr className={`divide-x border-grey-400`} key={rowIndex}>
                  {!compact && (
                    <td
                      className={twMerge(
                        "group flex items-center justify-center gap-2 px-7 py-4", // Add 'group' class
                        `${height === "small" ? "bg-bg-2" : ""}`,
                      )}
                    >
                      <div className="flex items-center gap-2">
                        {/* Smooth visibility transition */}
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(rowIndex)}
                          onChange={() => handleRowSelect(rowIndex)}
                          className={twMerge(
                            "ml-2 transition-opacity duration-300",
                            selectedRows.includes(rowIndex)
                              ? "opacity-100"
                              : "opacity-0 group-hover:opacity-100",
                          )} // Remains visible if checked
                        />
                        {rowIndex + startIndex}
                      </div>
                    </td>
                  )}
                  {finalColumns.map((column, columnIndex) => {
                    const normalizedColumn = column.toLowerCase();
                    if (hiddenColumnIds.has(normalizedColumn)) {
                      console.log("Hiding column:", column, "in row:", row);
                      return null; // Skip rendering this cell
                    }

                    const cell = getOriginalOrAltItem(
                      row,
                      alternativeColumns,
                      column,
                    );
                    const columnType =
                      column === companyNameColumn ? "name" : null;

                    let domainName = "";
                    if (columnType === "name" && domainColumn) {
                      domainName =
                        getOriginalOrAltItem(
                          row,
                          alternativeColumns,
                          domainColumn,
                        )?.value || "";
                    }
                    const cellKey = `${rowIndex}-${columnIndex}`;

                    return (
                      <RowCell
                        key={cellKey}
                        cell={cell}
                        domainName={domainName}
                        column={column}
                        columnIndex={columnIndex}
                        rowIds={rowIds || []}
                        rowIndex={rowIndex}
                        isCellLoading={false}
                        updateCell={updateCell}
                        type="normal"
                      />
                    );
                  })}
                  {visibleToolColumns?.map((column, columnIndex) => {
                    const normalizedColumn = column.id.toLowerCase();
                    if (hiddenColumnIds.has(normalizedColumn)) {
                      return null; // Skip rendering this cell
                    }

                    const cell = row
                      .filter(
                        (f) =>
                          f.data_field.toLowerCase() ===
                          column.data_field.toLowerCase(),
                      )
                      .at(-1);
                    const isCellLoading =
                      companyLoadingColumns &&
                      companyLoadingColumns[rowIndex]?.includes(column.id);

                    const cellKey = `${rowIndex}-${columnIndex}`;

                    return (
                      <RowCell
                        key={cellKey}
                        cell={cell}
                        column={column}
                        columnIndex={columnIndex + finalColumns.length}
                        domainName={""}
                        isCellLoading={isCellLoading === true}
                        rowIds={rowIds || []}
                        rowIndex={rowIndex}
                        updateCell={updateCell}
                        type="col"
                      />
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>

          <div className="my-4 flex flex-row items-center justify-start gap-2 pl-2 text-xs">
            <p>
              <a target="_blank" href="https://clearbit.com" rel="noreferrer">
                Logos provided by Clearbit
              </a>
            </p>
          </div>
        </div>
      </>
    );
  },
);
function RowCell({
  type,
  rowIds,
  cell,
  column,
  rowIndex,
  columnIndex,
  domainName,
  isCellLoading,
  updateCell,
}: {
  type: "col" | "normal";
  cell?: Field;
  column: Column | string;
  rowIds: string[];
  rowIndex: number;
  columnIndex: number;
  domainName: string;
  isCellLoading: boolean;
  updateCell({
    rowId,
    colId,
    newValue,
    onSuccess,
  }: {
    rowId: string;
    colId: string;
    newValue: string;
    onSuccess: () => void;
  }): Promise<void>;
}) {
  const { setFiltersOpen, setChatOpen, setDpeState } = useStateContext();
  const showCost = useShowCostQuery();
  if (isCellLoading) {
    console.log("");
  }
  const cellValue = cell?.value;
  const cellSource = cell?.source;
  const status = cell?.type === "custom_column" && cell?.status;
  const cellKey = `${rowIndex}-${columnIndex}`;

  const [waitingForSave, setWaitingForSave] = useState(false);
  const [editableValue, setEditableValue] = useState(cellValue);
  const [isEditing, setEditing] = useState(false);

  const handleDoubleClick = () => {
    setEditing(true);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEditableValue(event.target.value);
  };
  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      event.currentTarget.blur();
    }
  }

  const handleBlur = () => {
    setEditing(false);
    // updateCell(rowId, columnId, value);
    // console.log("NEW VALUE:", editableValue);
    if (!editableValue) {
      return;
    }
    if (editableValue === cellValue) {
      return;
    }
    updateCell({
      rowId: rowIds[rowIndex],
      colId: typeof column === "string" ? column : column.data_field,
      newValue:
        typeof editableValue === "string"
          ? editableValue
          : JSON.stringify(editableValue),
      onSuccess: () => {
        setTimeout(() => {
          setWaitingForSave(false);
        }, 1000);
      },
    });
    setWaitingForSave(true);
  };

  function handleCellClick(
    cell: Field | undefined,
    column: Column | string,
    rowIndex: number,
  ) {
    const rowId = rowIds?.[rowIndex];
    if (process.env.NODE_ENV !== "production") {
      console.log({ rowId, cell });
    }
    if (cell?.run_logs && cell.run_logs.length > 0) {
      setFiltersOpen(false);
      setChatOpen(true);
      setDpeState({
        isOpen: true,
        run_logs: cell.run_logs,
        cell: cell,
        type: "langsmith_dpe",
        column: column,
      });
    } else if (
      cell &&
      cell.type === "custom_column" &&
      typeof column !== "string" &&
      (column?.type === "tool_column" || column?.type === "custom_tool_column")
    ) {
      setFiltersOpen(false);
      setChatOpen(true);
      setDpeState({
        isOpen: true,
        cell: cell,
        column: column,
        type: "kuration_tool_dpe",
      });
    } else {
      setDpeState({
        isOpen: false,
        type: "null_dpe",
      });
    }
  }
  // if (type === "normal") {
  //   return (
  //     <td
  //       onClick={() => {
  //         handleCellClick(cell, column, rowIndex);
  //       }}
  //       key={cellKey}
  //       className={twMerge(
  //         "group relative h-12 cursor-pointer bg-white transition-all duration-300 hover:bg-lightpurple",
  //         `${columnIndex === 0 ? "sticky left-0 z-20" : ""}`,
  //         `${showCost && cell?.type === "final_researched" && cell.free === false ? "bg-orange-200" : ""}`,
  //       )}
  //     >
  //       <div className="max-w-[20ch] truncate  md:max-w-[24cqw]">
  //         <TableCell content={cellValue ?? ""} domainName={domainName} />
  //       </div>
  //       {cellSource && (
  //         /** the source tooltip is active on group hover */
  //         <SourceTooltip content={cellSource} />
  //       )}
  //     </td>
  //   );
  // }
  return (
    <td
      onDoubleClick={handleDoubleClick}
      onClick={() => {
        handleCellClick(cell, column, rowIndex);
      }}
      key={cellKey}
      className={twMerge(
        "group relative h-12 cursor-pointer bg-white transition-all duration-300 hover:bg-lightpurple",
        `${columnIndex === globalPinnedColumn ? "sticky left-0 z-20" : ""}`,
        `${showCost && cell?.type === "custom_column" && cell.free === false ? "bg-orange-200" : ""}`,
        `${cell?.re_run_able === true ? "text-red-500" : ""}`,
      )}
    >
      {status === "dependency_running" ? (
        <LoadingCell text={"Waiting for dependency"} />
      ) : isCellLoading || waitingForSave ? (
        <LoadingCell text={waitingForSave ? "Saving" : "Running"} />
      ) : status === "skipped" ? (
        <SkippedCell />
      ) : isEditing ? (
        <input
          className="h-full w-full px-3 py-4  outline-none"
          type="text"
          value={
            typeof editableValue === "string"
              ? editableValue
              : JSON.stringify(editableValue)
          }
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          autoFocus
        />
      ) : (
        <div>
          <div className="max-w-[20ch] truncate  md:max-w-[24cqw]">
            <CellText content={cellValue ?? ""} domainName={domainName} />
          </div>
          {(cellSource || (cell?.run_logs?.length || 0) > 0) && (
            /** the source tooltip is active on peer hover */
            <SourceTooltip content={cellSource || ""} />
          )}
        </div>
      )}
    </td>
  );
}
