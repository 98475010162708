import { useState, useMemo } from "react";
import { UserChat } from "../../models/data";
import { timeSince } from "../../components/utils";
import { ChatNameBox } from "./ChatNameBox";
import SearchIcon from "src/components/svgs/Search";

export function TopChats({
  userChats,
  showAllChats,
}: {
  userChats: UserChat[];
  showAllChats: boolean;
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const pinnedChats = userChats.filter((chat) => chat.isPinned);
  const unpinnedChats = userChats.filter((chat) => !chat.isPinned);

  const sortedUnpinnedChats = [...unpinnedChats].sort(
    (a, b) => b.runningColumns.length - a.runningColumns.length,
  );

  const todayChat = unpinnedChats.find((chat) =>
    timeSince(chat.timestamp.toDate())?.toLowerCase().includes("today"),
  );

  const filteredChats = useMemo(() => {
    if (!searchTerm.trim()) return sortedUnpinnedChats;

    return sortedUnpinnedChats.filter((chat) =>
      chat.chat_name?.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [searchTerm, sortedUnpinnedChats]);

  const unpinnedChatsToShow = filteredChats.filter(
    (chat) =>
      chat !== todayChat &&
      !pinnedChats.some((pinned) => pinned.chat_id === chat.chat_id),
  );

  return (
    <div className="flex flex-col gap-4 border-b border-grey-400 pb-4">
      {/* Render Pinned Section */}
      {pinnedChats.length > 0 && (
        <div className="flex flex-col gap-1 border-b border-grey-400 pb-4">
          <div className="p-2.5 text-base leading-[22.4px] text-grey-300">
            Pinned
          </div>
          <div className="flex flex-col gap-1">
            {pinnedChats.map((chat) => (
              <ChatNameBox key={chat.chat_id} chat={chat} />
            ))}
          </div>
        </div>
      )}

      <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3">
          <SearchIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
        </div>
        <input
          type="search"
          id="default-search"
          className="block w-full rounded border p-2 pl-10 ps-10"
          placeholder="Search chats..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {todayChat &&
        (!searchTerm ||
          todayChat.chat_name
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase())) && (
          <div className="flex flex-col gap-1">
            <div className="p-2.5 text-base leading-[22.4px] text-grey-300">
              Today
            </div>
            <div className="flex flex-col gap-1">
              <ChatNameBox key={todayChat.chat_id} chat={todayChat} />
            </div>
          </div>
        )}

      {/* Render Latest Projects Section */}
      {unpinnedChatsToShow.length > 0 && (
        <div className="flex flex-col gap-1">
          <div className="p-2.5 text-base leading-[22.4px] text-grey-300">
            Latest project
          </div>
          <div className="flex flex-col gap-1">
            {unpinnedChatsToShow
              .slice(0, showAllChats ? undefined : 5)
              .map((chat) => (
                <ChatNameBox key={chat.chat_id} chat={chat} />
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
