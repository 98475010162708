import * as React from "react";
const ActionIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5 4.89941L17.25 4.89941C17.7141 4.89941 18.1592 5.08379 18.4874 5.41198C18.8156 5.74017 19 6.18529 19 6.64941L19 8.39941M8.5 4.89941L6.75 4.89941C6.28587 4.89941 5.84075 5.08379 5.51256 5.41198C5.18437 5.74017 5 6.18529 5 6.64941L5 8.39941M19 15.3994L19 17.1494C19 17.6135 18.8156 18.0587 18.4874 18.3869C18.1592 18.715 17.7141 18.8994 17.25 18.8994L15.5 18.8994M5 15.3994L5 17.1494C5 17.6135 5.18437 18.0587 5.51256 18.3869C5.84075 18.715 6.28587 18.8994 6.75 18.8994L8.5 18.8994"
      stroke="black"
      strokeWidth={1.28035}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={12.0508}
      cy={11.8491}
      r={2}
      transform="rotate(90 12.0508 11.8491)"
      fill="#111010"
    />
  </svg>
);
export default ActionIcon;
