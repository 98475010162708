import * as React from "react";

interface PinIconProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

const PinIcon = ({ color = "#F44336", ...props }: PinIconProps) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.05108 1.33194C7.36508 1.12394 7.89708 0.975442 8.29858 1.37694L10.6221 3.70094C11.0251 4.10294 10.8761 4.63494 10.6676 4.94844C10.5547 5.11843 10.4078 5.26315 10.2361 5.37344C10.0691 5.47994 9.85808 5.56044 9.63058 5.54844C9.52051 5.54065 9.41077 5.5288 9.30158 5.51294L9.26758 5.50794C9.15027 5.49113 9.03234 5.47895 8.91408 5.47144C8.66208 5.45894 8.56508 5.50144 8.53408 5.53144L7.28908 6.77694C7.24908 6.81694 7.19908 6.90594 7.16108 7.07694C7.12458 7.24194 7.10858 7.44494 7.10458 7.66994C7.10108 7.88594 7.10858 8.10694 7.11658 8.31994L7.11708 8.34344C7.12458 8.55494 7.13208 8.77094 7.12158 8.94044C7.08908 9.45594 6.68758 9.83544 6.29258 10.0109C5.89758 10.1859 5.33408 10.2294 4.94258 9.83744L3.81758 8.71244L1.76508 10.7649C1.73075 10.8018 1.68935 10.8313 1.64335 10.8518C1.59735 10.8723 1.54769 10.8833 1.49734 10.8842C1.44699 10.8851 1.39697 10.8759 1.35028 10.857C1.30358 10.8381 1.26117 10.8101 1.22556 10.7745C1.18995 10.7389 1.16188 10.6964 1.14302 10.6497C1.12415 10.603 1.11489 10.553 1.11578 10.5027C1.11667 10.4523 1.12769 10.4027 1.14819 10.3567C1.16868 10.3107 1.19823 10.2693 1.23508 10.2349L3.28708 8.18244L2.16208 7.05744C1.77058 6.66544 1.81358 6.10244 1.98908 5.70744C2.16408 5.31244 2.54408 4.91094 3.05908 4.87844C3.22908 4.86794 3.44508 4.87544 3.65658 4.88294L3.68008 4.88344C3.89308 4.89094 4.11408 4.89894 4.33008 4.89544C4.55508 4.89144 4.75808 4.87544 4.92308 4.83894C5.09408 4.80094 5.18308 4.75044 5.22308 4.71044L6.46808 3.46544C6.49858 3.43494 6.54108 3.33744 6.52808 3.08544C6.52057 2.96717 6.50839 2.84925 6.49158 2.73194L6.48708 2.69794C6.47121 2.58875 6.45937 2.47901 6.45158 2.36894C6.43908 2.14144 6.51958 1.93044 6.62558 1.76344C6.73358 1.59344 6.88308 1.44344 7.05108 1.33194Z"
      fill={color} // Use the color prop
    />
  </svg>
);
export default PinIcon;
