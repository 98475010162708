"use client";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../context/StateContext";
import Sidebar from "../pages/sidebar/Sidebar";
import Navbar from "./NavBar";
import { twMerge } from "tailwind-merge";
import FullScreenLottie from "src/lotties/FullScreenLottie";
import { Home } from "./Home";
import useOnboarding from "src/hooks/useOnboarding";


export default function HomeLayout() {
  const { sidebarOpen } = useStateContext();
  const { appInitialized, user } = useAuth();
  const { onBoarding } = useOnboarding(user?.uid ?? null);

  const navigate = useNavigate();
  useEffect(() => {
    if (!appInitialized) {
      return;
    }
    if (!user) {
      navigate("/login");
    }
    if (!user?.emailVerified) {
      navigate("/verify");
    }
    if (onBoarding) {
      navigate("/onboarding");
    }
  }, [user, appInitialized, onBoarding, navigate]);

  if (!appInitialized) {
    return <FullScreenLottie />;
    // return <Spinner text="Kuration.io is loading..." />;
  }
  return (
    <div className="relative flex max-h-screen w-full flex-col overflow-hidden bg-white">
      <div className={twMerge("sticky top-0 z-40 w-full bg-dark md:hidden")}>
        <Navbar />
      </div>

      <div className="flex w-full flex-grow">
        {/* Sidebar wrapper - dynamic width based on state */}
        <div
          className={`hidden transition-all duration-300 ease-out md:block ${
            sidebarOpen ? "w-[268px]" : "w-20"
          }`}
        >
          <Sidebar />
        </div>

        {/* Mobile sidebar - absolute positioned */}
        <div className="md:hidden">
          <Sidebar />
        </div>

        {/* Main content area - adjusts with sidebar */}
        <div
          className={`flex-1 transition-all duration-300 ease-out ${
            sidebarOpen ? "md:ml-0" : "md:ml-0"
          }`}
        >
          <div className="flex h-[calc(100vh_-_78px)] flex-grow overflow-auto bg-bg-2 pr-0 pt-4 transition-all duration-300 ease-out md:h-screen md:p-9 md:pl-5 md:pr-0">
            <div className="max-h-full w-full overflow-auto">
              <Home />
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}