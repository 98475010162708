import { useState } from "react";
import { useNavigate } from "react-router-dom";
import backend_services from "src/services/backend_service";
import Spinner from "../elements/Spinner";
import { toast } from "react-toastify";
import { useAuth } from "src/context/AuthContext";
import { TableState } from "src/hooks/useChatTable";
import CloseIcon from "../svgs/CloseIcon";
import IndeedJobScraperForm from "./forms/IndeedJobScraperForm";

interface IndeedJobScraperProps {
  handleClose: () => void;
  initialSearchUrl: string;
  table_state?: TableState;
  chat_id?: string;
}

const IndeedJobScraper: React.FC<IndeedJobScraperProps> = ({
  handleClose,
  initialSearchUrl = "",
  table_state,
  chat_id,
}) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [searchUrl, setSearchUrl] = useState(initialSearchUrl);
  const navigator = useNavigate();

  async function handleSearch() {
    try {
      setLoading(true);

      if (!user) {
        toast.error("User is not authenticated.");
        return;
      }

      if (!searchUrl || searchUrl.trim() === "") {
        toast.error("Please enter a valid job title.");
        return;
      }

      const indeedUrlPattern = /^https?:\/\/(?:[a-z]{2}\.)?indeed\.com.*$/;
      if (!indeedUrlPattern.test(searchUrl)) {
        toast.error("Please enter a valid Indeed.com URL.");
        setLoading(false);
        return;
      }

      if (!indeedUrlPattern) {
        toast.error("Please enter a valid Indeed URL");
      }

      const requestBody: any = {
        search_url: searchUrl,
      };

      let response;
      if (table_state === TableState.ERROR) {
        // Retry endpoint
        response = await backend_services.fetchProtectedData(
          `/initial_lists/retry/indeedJob/${chat_id}`,
          user.getIdToken(),
          undefined,
          "POST",
          requestBody,
        );

        if (response.ok) {
          const result = await response.json();
          toast.success(result.message || "Retry initiated successfully.");
          handleClose(); // Close modal after retry
        } else {
          throw new Error(await response.text());
        }
      } else {
        // Default endpoint
        response = await backend_services.fetchProtectedData(
          `/initial_lists/indeedJob`,
          user.getIdToken(),
          undefined,
          "POST",
          requestBody,
        );

        if (response.ok) {
          const result = await response.json();
          if (result?.chat_id) {
            navigator(`/chat/${result.chat_id}`); // Redirect to chat only for default request
            toast.success(result.message || "Request submitted successfully.");
            handleClose(); // Close modal after redirect
          } else {
            toast.error(result.detail || "An error occurred.");
          }
        } else {
          throw new Error(await response.text());
        }
      }
    } catch (error: any) {
      console.error("Error:", error.message || error);
      console.log("erryyaaaa", error.message);
      toast.error(error.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative w-[40rem] rounded-lg bg-white p-8 shadow-lg">
        {/* Modal with increased width to 40rem (640px) */}
        {loading && <Spinner text="Searching..." onWhiteBackground />}
        <div className="flex flex-col gap-6">
          {/* Increased gap between elements */}
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-bold">Indeed Job Scraper</h2>
            {/* Increased font size for title */}
            <button onClick={handleClose}>
              <CloseIcon />
            </button>
          </div>
          <hr className="w-full" />

          <IndeedJobScraperForm
            searchUrl={searchUrl}
            setSearchUrl={setSearchUrl}
            onSubmit={handleSearch}
          />
        </div>
      </div>
    </div>
  );
};

export default IndeedJobScraper;
