// StateContext.js
import React, { createContext, useContext, useState } from "react";
import { AppState, ChatType } from "../models/appState";
import { DpeState } from "src/models/Dpe";

// Extend AppState to include remainingRows and filterWiseRows
interface AppStateWithRemainingRows extends AppState {
  remainingRows: number | null;
  setRemainingRows: (count: number) => void;
  filterWiseRows: Record<string, number>;
  setFilterWiseRows: (filterRows: Record<string, number>) => void;
  selectedRows: number[];
  setSelectedRows: React.Dispatch<React.SetStateAction<number[]>>;
}

const StateContext = createContext<AppStateWithRemainingRows>({
  chatOpen: false,
  setChatOpen: () => {},
  chatFullScreen: false,
  setChatFullScreen: () => {},
  filtersOpen: true,
  setFiltersOpen: () => {},
  sidebarOpen: window.innerWidth > 770,
  setSidebarOpen: () => {},
  chatType: "refine",
  setChatType: (value) => {},
  dpeState: { isOpen: false, type: "null_dpe" },
  setDpeState: () => {},
  remainingRows: null,
  setRemainingRows: () => {},
  filterWiseRows: {},
  setFilterWiseRows: () => {},
  selectedRows: [],
  setSelectedRows: () => {},
  showQueryBox: false,
  setShowQueryBox: () => {},
});

export const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [chatOpen, setChatOpen] = useState(false);
  const [chatFullScreen, setChatFullScreen] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [showQueryBox, setShowQueryBox] = useState(false);
  const [chatType, setChatType] = useState<ChatType>("refine");
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const [dpeState, setDpeState] = useState<DpeState>({
    isOpen: false,
    type: "null_dpe",
  });
  const [remainingRows, setRemainingRows] = useState<number | null>(null);
  const [filterWiseRows, setFilterWiseRows] = useState<Record<string, number>>(
    {},
  );

  return (
    <StateContext.Provider
      value={{
        chatOpen,
        setChatOpen,
        chatFullScreen,
        setChatFullScreen,
        sidebarOpen,
        setSidebarOpen,
        chatType,
        setChatType,
        filtersOpen,
        setFiltersOpen,
        dpeState,
        setDpeState,
        remainingRows,
        setRemainingRows,
        filterWiseRows,
        setFilterWiseRows,
        selectedRows,
        setSelectedRows,
        showQueryBox,
        setShowQueryBox,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => {
  return useContext(StateContext);
};
