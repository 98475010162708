import { CustomToolColumn, KurationToolColumn } from "src/hooks/useChatTable";
import CustomDropdown from "../elements/CustomDropDownMenu";
import HashtagIcon from "../svgs/Hashtag";
import NextIcon from "../svgs/Next";

export function runMenu({
  colRunner,
  col,
}: {
  col: KurationToolColumn | CustomToolColumn;
  colRunner: (
    col: KurationToolColumn | CustomToolColumn,
    count: number,
    pause: boolean,
    error_only: boolean,
  ) => Promise<void>;
}) {
  if (col.run_status === "running") {
    return [
      <button
        onClick={() => {
          colRunner(col, 0, true, false);
        }}
        className="flex w-full gap-2 rounded px-2.5 py-2 hover:bg-grey-600"
      >
        <div className="flex-grow text-left">Pause</div>
      </button>,
    ];
  }
  if (col.run_status === "pausing") {
    return [];
  }
  return [
    <button
      onClick={() => {
        colRunner(col, 1, false, false);
      }}
      className="flex w-full gap-2 rounded px-2.5 py-2 hover:bg-grey-600"
    >
      <div className="flex-grow text-left">Run next 1 row</div>
    </button>,
    <button
      onClick={() => {
        colRunner(col, 10, false, false);
      }}
      className="flex w-full gap-2 rounded px-2.5 py-2 hover:bg-grey-600"
    >
      <div className="flex-grow text-left">Run next 10 rows</div>
    </button>,
    <button
      onClick={() => {
        colRunner(col, 50, false, false);
      }}
      className="flex w-full gap-2 rounded px-2.5 py-2 hover:bg-grey-600"
    >
      <div className="flex-grow text-left">Run next 50 rows</div>
    </button>,
    <button
      onClick={() => {
        colRunner(col, -1, false, false);
      }}
      className="flex w-full gap-2 rounded px-2.5 py-2 hover:bg-grey-600"
    >
      <div className="flex-grow text-left">Run next all rows</div>
    </button>,
    <button
      onClick={() => {
        colRunner(col, -1, false, true);
      }}
      className="flex w-full gap-2 rounded px-2.5 py-2 hover:bg-grey-600"
    >
      <div className="flex-grow text-left">
        Run <span className="text-red-500">Error</span> rows only
      </div>
    </button>,
  ];
}

export function rowsMenu() {
  return [
    <div>
      <CustomDropdown
        classNames="w-full"
        opener={
          <button className="flex w-full gap-2 rounded px-2.5 py-2 hover:bg-grey-600">
            <div>
              <HashtagIcon />
            </div>
            <div className="flex-grow text-left">Coming Soon</div>
            <div>
              <NextIcon />
            </div>
          </button>
        }
        overrideClasses=""
        options={rowLimitMenu()}
      />
    </div>,
  ];
}

export function rowLimitMenu() {
  return [
    <div>
      <CustomDropdown
        classNames="w-full"
        opener={
          <button className="flex w-full gap-2 rounded px-2.5 py-2 hover:bg-grey-600">
            <div>
              <HashtagIcon />
            </div>
            <div className="flex-grow text-left">Coming Soon</div>
            <div>
              <NextIcon />
            </div>
          </button>
        }
        overrideClasses=""
        options={colLimitMenu()}
      />
    </div>,
  ];
}

export function colLimitMenu() {
  return [
    <div>
      <CustomDropdown
        classNames="w-full"
        opener={
          <button className="flex w-full gap-2 rounded px-2.5 py-2 hover:bg-grey-600">
            <div>
              <HashtagIcon />
            </div>
            <div className="flex-grow text-left">Coming Soon</div>
            <div>
              <NextIcon />
            </div>
          </button>
        }
        overrideClasses=""
        options={[]}
      />
    </div>,
  ];
}
