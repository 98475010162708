import CloseIcon from "../svgs/CloseIcon";
import { useNavigate } from "react-router-dom";
import GoogleMapPin from "../svgs/GoogleMapPin";
import SearchIcon from "../svgs/Search";
import backend_services from "src/services/backend_service";
import Spinner from "../elements/Spinner";
import { useAuth } from "src/context/AuthContext";
import { useState } from "react";
import { InitialListItem } from "src/models/InitialList";
import { toast } from "react-toastify";

const GoogleMapList = ({
  item,
  handleClose,
}: {
  item: InitialListItem;
  handleClose: () => void;
}) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [userQuery, setUserQuery] = useState("");
  const [numberOfResult, setNumberOfResult] = useState("");
  const navigator = useNavigate();

  async function handleMessageSend() {
    try {
      setLoading(true);
      if (user) {
        if (numberOfResult === "") {
          setNumberOfResult("20");
        }
        const result = await backend_services
          .fetchProtectedData(
            `/initial_lists/googleMap`,
            user.getIdToken(),
            undefined,
            "POST",
            {
              query: userQuery,
              numOfResult: parseInt(numberOfResult),
            },
          )
          .then((res) => {
            setLoading(false);
            return res.json();
          });
        if (
          result.data &&
          result.data.chat_id &&
          typeof result.data.chat_id === "string"
        ) {
          navigator(`/chat/${result.data.chat_id}`);
        } else {
          if (result.message) {
            toast.error(result.message);
          } else if (result.detail) {
            toast.error(result.detail);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div
      className={`fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform ${loading ? "opacity-95" : null} z-50 w-[700px] rounded-lg bg-white px-8 py-4 shadow-lg`}
    >
      {loading && <Spinner text="Searching" onWhiteBackground />}
      <div className="flex flex-col gap-5">
        <div className="grid grid-cols-6 items-center">
          <GoogleMapPin />
          <div className="col-span-4 col-start-2 justify-self-center text-nowrap text-center text-xl font-bold text-gray-900">
            <h2>{item.description}</h2>
          </div>
          <button
            className="col-end-7 justify-self-end text-gray-500 hover:text-gray-700"
            onClick={handleClose}
          >
            <CloseIcon />
          </button>
        </div>
        <hr className="w-full" />
        <div className="relative grid gap-10">
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <p>What business do you want to extract from GoogleMaps?</p>
            </div>
            <textarea
              placeholder={"Painting shops in Hong Kong"}
              onChange={(e) => setUserQuery(e.target.value)}
              className=" peer inline-flex max-h-14  w-full resize-none items-center justify-center gap-2.5 rounded-md border-2 border-neutral-400  bg-white py-3.5  pl-3 pr-11 shadow-[2px_2px_20px_0px_#00000014]  outline-none hover:border-purple  md:pl-4"
            ></textarea>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <p>How many businesses do you want to extract?</p>
              <p className="text-gray-400">(Option)</p>
            </div>
            <textarea
              placeholder={"20"}
              onChange={(e) => setNumberOfResult(e.target.value)}
              className=" peer inline-flex max-h-14  w-full resize-none items-center justify-center gap-2.5 rounded-md border-2 border-neutral-400  bg-white py-3.5  pl-3 pr-11 shadow-[2px_2px_20px_0px_#00000014]  outline-none hover:border-purple  md:pl-4"
            ></textarea>
          </div>
          <hr className="w-full" />
          <div className="flex justify-between">
            <div className="items-center">
              Reminder: 1 credit for 100 results
            </div>
            <button
              onClick={() => {
                handleMessageSend();
              }}
              className="flex items-center gap-2 rounded bg-purple p-[7px] text-white outline-none hover:bg-purpleHover focus:bg-purpleHover"
            >
              <p>Search</p>
              <div>
                <SearchIcon height="20" width="20" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleMapList;
