import {
  AggregateField,
  AggregateQuerySnapshot,
  DocumentData,
  DocumentSnapshot,
  collection,
  endBefore,
  getCountFromServer,
  getDocs,
  getFirestore,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import firebase_app from "./config";
import { RowFilter } from "src/hooks/useChatTable";
import { getFiltersQueries } from "src/hooks/useTableRows";

export const getCount = async (
  collectionName: string,
  numPerPages: number,
  row_filters: { [id: string]: RowFilter },
) => {
  const dataCollection = collection(getFirestore(firebase_app), collectionName);
  let count: null | AggregateQuerySnapshot<
    {
      count: AggregateField<number>;
    },
    DocumentData,
    DocumentData
  > = null;
  try {
    let dataQuery = query(dataCollection, ...getFiltersQueries(row_filters));
    count = await getCountFromServer(dataQuery);
  } catch (error) {
    console.log(error);
  }

  return count?.data().count || 0;
};

// if you need pagination data
export const getPaginatedData = async (
  collectionS: string, // Name of the collection
  orderByProp: string, // Property to order by
  row_filters: { [id: string]: RowFilter },
  direction: "next" | "prev" | undefined,
  startAfterDoc?: DocumentSnapshot,
  endBeforeDoc?: DocumentSnapshot,
  numPerPage: number = 100,
) => {
  const dataCollection = collection(getFirestore(firebase_app), collectionS);

  // Define the initial data query with ordering and limit
  let dataQuery = query(
    dataCollection,
    orderBy(orderByProp),
    ...getFiltersQueries(row_filters),
    limit(numPerPage),
  );

  // Update query based on direction and provided documents
  if (direction === "next" && startAfterDoc) {
    // For next direction, start after the provided document
    dataQuery = query(dataQuery, startAfter(startAfterDoc));
  } else if (direction === "prev" && endBeforeDoc) {
    // For previous direction, end before the provided document and limit to last
    dataQuery = query(
      dataCollection,
      ...getFiltersQueries(row_filters),
      orderBy(orderByProp),
      endBefore(endBeforeDoc),
      limitToLast(numPerPage),
    );
  }

  // Get snapshot of documents based on the final query
  const itemsSnapshot = await getDocs(dataQuery);
  // Extract data from each document and map to an array of objects
  const items = itemsSnapshot.docs.map((doc) => doc.data());
  // Return an object retrieved items, and first and last documents
  return {
    result: items, // Cast items to an array of Item objects
    lastDoc: itemsSnapshot.docs[itemsSnapshot.docs.length - 1],
    firstDoc: itemsSnapshot.docs[0],
  };
};
