import { Timestamp } from "firebase/firestore";
import { ReactNode } from "react";
import { Field } from "./tools";
import { Column } from "src/hooks/useChatTable";

export interface FreeDict {
  [key: string]: any;
}
export interface WithId {
  id: string;
}

export interface Chat {
  chat_id: string;
  timestamp: Timestamp;
  chat_name?: string;
  table_id?: string | null;
  version: number;
  progress: number;
  duplicate_chat_loading: boolean;
  isDeleted?: boolean;
  [key: string]: any;
}
export class UserChat implements Chat {
  chat_id: string;
  timestamp: Timestamp;
  chat_name?: string;
  table_id: string | null;
  version: number;
  progress: number;
  duplicate_chat_loading: boolean;
  isDeleted: boolean;
  isPinned: boolean;
  runningColumns: Column[];

  constructor(chat: Chat) {
    this.chat_id = chat.chat_id;
    this.timestamp = chat.timestamp;
    this.chat_name = chat.chat_name;
    this.version = chat.version || 1;
    this.table_id = chat.table_id || null;
    this.progress = chat.progress || 0;
    this.duplicate_chat_loading = chat.duplicate_chat_loading;
    this.isDeleted = chat.isDeleted || false;
    this.isPinned = chat.isPinned;
    this.runningColumns = Object.values(
      (chat.running_columns as Record<string, unknown>) || {},
    ).map((column) => column as Column);
  }
}

export interface Message {
  role: "user" | "assistant" | "system" | "logging";
  content: string | null;
  data?: Record<string, string>[];
  time: string;
  type?: "error" | "user_list";
}

export type CreateAndSendMessage = (
  messageText: string,
  userUploadedJson?: Record<string, string>[] | null,
  hiddenMessage?: boolean,
  type?: "contact_form" | "manual_search" | "prompt",
  visualText?: string,
) => Promise<void>;
export type Company = Array<Field>;

export interface FinalListItem {
  final_research_results: Company;
}
export interface ResearchPlanItem {
  data_field: string;
  Question: string;
  Validation_Question?: string;
  Answer_Structure?: string;
}
export interface ModalProps {
  isOpen: boolean;
  header: ReactNode;
  body: ReactNode;
  actions: ReactNode[];
}

export class TableRow {
  id: string;
  company: Company;
  columns_loading?: string[];
  loading: boolean;
  status: "user_added";
  timestamp: Timestamp;

  constructor(row: any) {
    this.id = row.id;
    this.columns_loading = row.columns_loading;
    this.loading = row.loading;
    this.status = row.status;
    this.timestamp = row.timestamp;
    if (Array.isArray(row.company)) {
      this.company = row.company;
    } else {
      this.company = Object.keys(row.company).map((k) => row.company[k]);
    }
  }
}

export type { Field };
