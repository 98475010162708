import {
  CheckCircleIcon,
  DocumentTextIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/outline";
import { Timestamp } from "firebase/firestore";
import AccordionItem from "./AccordionItem";
import { ReactNode } from "react";
import RequestObjective from "./RequestObjective";
import { CompanyListTable } from "../../components/table/CompanyListTable";
import { normalizeCompanies, reshapeCompanyData } from "../../components/utils";
import { ChatUpdate } from "src/models/updates";

export default function RequestStatus({
  chatUpdates,
}: {
  chatUpdates: ChatUpdate[];
}) {
  return (
    <div
      className={`mt-20 w-full  rounded-lg  border  border-dark border-opacity-50 mb-20 `}
    >
      {chatUpdates.map((update) => {
        if (update.type === "objective_generated") {
          return (
            <UpdateItem
              key={update.timestamp.toString()}
              Icon={RocketLaunchIcon}
              text="Deploying bots to gather data for the request."
              time={update.timestamp}
              content={
                update.data && <RequestObjective requestData={update.data} />
              }
            />
          );
        }
        if (update.type === "initial_list_complete") {
          const companies = normalizeCompanies(update.data);
          return (
            <UpdateItem
              key={update.timestamp.toString()}
              Icon={DocumentTextIcon}
              text="Initial List completed. Refining Data Now."
              time={update.timestamp}
              content={
                update.data && (
                  <CompanyListTable
                    companies={companies}
                    height="small"
                    startIndex={1}
                  />
                )
              }
            />
          );
        } else if (update.type === "final_list_complete") {
          const companies = reshapeCompanyData(update.data);
          return (
            <UpdateItem
              key={update.timestamp.toString()}
              Icon={CheckCircleIcon}
              text="Request Complete"
              time={update.timestamp}
              content={
                <CompanyListTable
                  companies={companies}
                  height="small"
                  startIndex={1}
                />
              }
            />
          );
        } else {
          return <div key={"unknown list " + update.timestamp.toString()}></div>;
        }
      })}
    </div>
  );
}

function UpdateItem({
  Icon,
  text,
  time,
  content,
}: {
  Icon: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & React.RefAttributes<SVGSVGElement>
  >;
  text: string;
  time: Timestamp;
  content: ReactNode;
}) {
  return (
    <AccordionItem
      title={
        <div className="flex items-center gap-3">
          <Icon height={30} />
          <div>
            <span>{text}</span>
            <div className="text-xs">{time.toDate().toLocaleString()} </div>
          </div>
        </div>
      }
      content={content}
    />
  );
}
