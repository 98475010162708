import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IconButton } from "src/components/elements/IconButton";
import StarsIcon from "src/components/svgs/Stars";
// import ThreeDotsIcon from "src/components/svgs/ThreeDots";
import { sentenceCase } from "src/components/utils";
import { CustomTool } from "src/models/tools";
import { useAuth } from "src/context/AuthContext";
// import useChatDetails from "src/hooks/useChatDetails";
// import useDefaultTable, { ToolColumn } from "src/hooks/useChatTable";
import backend_services from "src/services/backend_service";
import { useState } from "react";
import { SimpleSpinner } from "src/components/elements/Spinner";
import { parseFilterForm } from "./filterUtils";
import { FilterFormField } from "src/components/preCuratedLists/FilterFields";
import { ColumnConditionAdder } from "src/components/columnAdder/ColumnForm";
import useDefaultTable from "src/hooks/useChatTable";
import useChatDetails from "src/hooks/useChatDetails";

export const CustomFilterDetails = ({
  selectedTool,
}: {
  selectedTool: CustomTool;
}) => {
  const { chatId } = useParams();
  const { user } = useAuth();

  const { chatDetails } = useChatDetails(chatId ?? null);
  const tableId = chatDetails?.table_id;
  const [addColumnLoading, setAddColumnLoading] = useState(false);
  const [filterForm, setFilterForm] = useState<FilterFormField[]>([]);
  const [conditionalColumn, setConditionalColumn] = useState(false);
  const { data: defaultTable } = useDefaultTable(tableId);

  async function add_custom_tool_column() {
    if (!user || !selectedTool) {
      return;
    }
    const columnConditions = parseFilterForm(
      filterForm,
      defaultTable?.column_list || [],
    );
    setAddColumnLoading(true);
    await backend_services
      .fetchProtectedData(
        `/chat/${chatId}/column/custom_tool/${selectedTool.id}`,
        user?.getIdToken(),
        undefined,
        "POST",
        { column_conditions: columnConditions },
      )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("invalid result");
        }

        toast.success("New column added at the end of table.");
        // onSubmit(selectedTool);
        return res.json();
      })
      .catch((err) => {
        toast.error("Failed to create column. Please try again.");
        setAddColumnLoading(false);
      });
    setAddColumnLoading(false);
  }

  return (
    <div className="flex flex-1 flex-col">
      <div className="px-6">
        <CustomFilterHead selectedTool={selectedTool} />
        {addColumnLoading ? (
          <div className="flex h-full items-center justify-center gap-2 py-5">
            <SimpleSpinner radius={20} overrideClasses="border-black" />
            loading..
          </div>
        ) : (
          <>
            <CustomToolFieldsDetails selectedTool={selectedTool} />
            <div className="pt-12">
              <ColumnConditionAdder
                key={"conditions"}
                columns={defaultTable?.column_list || []}
                conditionalColumn={conditionalColumn}
                filterForm={filterForm}
                setConditionalColumn={setConditionalColumn}
                setFilterForm={setFilterForm}
              />
            </div>
          </>
        )}
      </div>

      <div className="mt-4 mb-9 px-6">
        <IconButton
          overrideClasses="h-9 gap-1.5 border-purple text-white  md:h-[42px]  "
          onClick={() => {
            add_custom_tool_column();
          }}
          variant="fill"
          icon={<StarsIcon className="h-4 w-4 md:h-6 md:w-6" fill="white" />}
          text={
            <div className="min-w-max text-sm  md:text-lg ">Add as column</div>
          }
        />
      </div>
    </div>
  );
};

export function CustomFilterHead({
  selectedTool,
}: {
  selectedTool: Omit<CustomTool, "id">;
}) {
  return (
    <div className="flex items-center justify-between py-4">
      <div className="flex items-center gap-4">
        <div className="flex h-9  items-center gap-2">
          <div className="h-6 w-6">
            <StarsIcon fill="#e08588" />
          </div>
          <div className="text-lg">{selectedTool.fields.name}</div>
        </div>
      </div>
    </div>
  );
}

export const CustomToolFieldsDetails = ({
  selectedTool,
}: {
  selectedTool: Omit<CustomTool, "id">;
}) => {
  return (
    <div>
      <div className="mb-5 ">Details</div>
      <ul className="m-0 list-none rounded-md bg-gray-100 px-4 py-6 marker:ml-2">
        {[
          { key: "Objective", value: selectedTool.fields.objective },
          { key: "Desired Outcome", value: selectedTool.fields.outcome },
          { key: "Output Format", value: selectedTool.fields.format },
          { key: "Default Value", value: selectedTool.fields.default },
        ].map((f) => {
          return (
            <li className="" key={f.key}>
              <span className="text-neutral-700">
                {sentenceCase(f.key, "_")} -{" "}
              </span>{" "}
              <span className="text-neutral-400">
                {typeof f.value === "string"
                  ? f.value
                  : Array.isArray(f.value)
                    ? `One of: [${f.value.join(", ")}]`
                    : ""}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
