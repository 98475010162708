import CloseIcon from "../svgs/CloseIcon";
import Spinner from "../elements/Spinner";
import { InitialListItem } from "src/models/InitialList";
import { UploadTier } from "src/pages/toolbar/UploadTier";
import UploadIcon from "../svgs/Upload";
import { useRef, useState } from "react";
import ChatContainer from "src/pages/chat/ChatContainer";
import { CreateAndSendMessage } from "src/models/data";

const CSVFileUploader = ({
  item,
  handleClose,
}: {
  item: InitialListItem;
  handleClose: () => void;
}) => {
  const chatContainerRef = useRef<{
    createAndSendMessage: CreateAndSendMessage;
  }>(null);
  const [loading, setLoading] = useState(false);
  const [userUploadedJson, setUserUploadedJson] = useState<
    Record<string, string>[] | null
  >(null);

  const handleSendMessage = async (msg: string) => {
    if (chatContainerRef.current) {
      setLoading(true);
      await chatContainerRef.current.createAndSendMessage(
        msg,
        userUploadedJson,
      );
    }
  };

  return (
    <div
      className={`fixed left-1/2 top-1/2 z-50 w-[700px] -translate-x-1/2 -translate-y-1/2 transform rounded-lg bg-white px-8 py-4 shadow-lg`}
    >
      {loading && (
        <div className="absolute inset-0 z-50 flex items-center justify-center bg-white bg-opacity-65">
          <Spinner text="Loading..." onWhiteBackground />
        </div>
      )}
      <div className="hidden">
        <ChatContainer ref={chatContainerRef} />
      </div>
      <div className="flex flex-col gap-5">
        <div className="grid grid-cols-6 items-center">
          <UploadIcon />
          <div className="col-span-4 col-start-2 justify-self-center text-nowrap text-center text-xl font-bold text-gray-900">
            <h2>{item.description}</h2>
          </div>
          <button
            className="col-end-7 justify-self-end text-gray-500 hover:text-gray-700"
            onClick={handleClose}
          >
            <CloseIcon />
          </button>
        </div>
        <hr className="w-full" />
        <div className="relative grid gap-3">
          <div
            className={`flex flex-col gap-2 ${
              userUploadedJson !== null ? "" : "pb-2"
            } overflow-auto`}
          >
            <UploadTier setUserUploadedJson={setUserUploadedJson} />
          </div>
          {userUploadedJson !== null && (
            <>
              <hr className="w-full" />
              <div className="flex justify-end">
                <button
                  onClick={() =>
                    handleSendMessage(
                      "Here's the CSV. Let me know what's next.",
                    )
                  }
                  className="flex items-center gap-2 rounded bg-purple p-[7px] text-white outline-none hover:bg-purpleHover focus:bg-purpleHover"
                >
                  <p>Submit</p>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CSVFileUploader;
