import { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { categorizedToolIds } from "src/components/columnAdder/ToolsList";
import { useStateContext } from "src/context/StateContext";
import { SimpleSpinner } from "src/components/elements/Spinner";
import { FilterTags } from "./FilterTags";
import { FilterCard } from "./FilterCard";
import { CustomTool, KurationTool, Tool } from "src/models/tools";
import { CustomFiltersList } from "./CustomFiltersList";
import { ColumnConditions } from "../../../src/components/columnAdder/ColumnForm";
import useDefaultTable, { Recipe } from "../../../src/hooks/useChatTable";
import useChatDetails from "../../../src/hooks/useChatDetails";
import { FilterFormField } from "../../../src/components/preCuratedLists/FilterFields";
import RecipeList from "./RecipeList";
import SearchIcon from "src/components/svgs/Search";

export const FiltersMain = ({
  isLoading,
  error,
  tools,
  retryTools,
  customTools,
  customToolsLoading,
  setSelectedTool,
  setShowCreateCustomFilter,
  filterForm,
  setFilterForm,
  setSelectedRecipe,
}: {
  isLoading: boolean;
  error: any;
  tools: KurationTool[];
  customToolsLoading: boolean;
  customTools: CustomTool[];
  retryTools: () => void;
  setShowCreateCustomFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTool: React.Dispatch<React.SetStateAction<Tool | null>>;
  filterForm: FilterFormField[];
  setFilterForm: React.Dispatch<React.SetStateAction<FilterFormField[]>>;
  setSelectedRecipe: React.Dispatch<React.SetStateAction<Recipe | null>>;
}) => {
  const [filter, setFilter] = useState<
    "all" | "kuration" | "custom" | "history"
  >("all");
  const [showListFilters] = useState(false); // can add it back later
  const [searchTerm, setSearchTerm] = useState("");
  const { chatId } = useParams();
  const { chatDetails } = useChatDetails(chatId ?? null);
  const tableId = chatDetails?.table_id;
  const { data: defaultTable } = useDefaultTable(tableId);
  const { chatFullScreen } = useStateContext();

  const filteredTools = useMemo(() => {
    return Object.entries(categorizedToolIds).reduce(
      (acc, [category, toolIds]) => {
        // Filter tools by matching IDs from categorizedTools and ensuring they exist in tools
        const matchedTools = toolIds
          .map((id) =>
            tools.find(
              (tool) =>
                tool.id === id &&
                tool.name.toLowerCase().includes(searchTerm.toLowerCase()),
            ),
          )
          .filter(Boolean) as KurationTool[];

        if (matchedTools.length) {
          acc[category] = matchedTools;
        }
        return acc;
      },
      {} as Record<string, KurationTool[]>,
    );
  }, [searchTerm, tools]);

  return (
    <>
      {showListFilters ? (
        <ColumnConditions
          columns={defaultTable?.column_list || []}
          filterForm={filterForm}
          setFilterForm={setFilterForm}
          runFilter={true}
        />
      ) : (
        <>
          <div className="inline-flex flex-col items-start justify-start gap-[33px] border-b border-neutral-300 bg-white px-6 py-9">
            <div className="text-lg font-normal text-black">
              Enrichment library
            </div>
            <div className="inline-flex items-center justify-start gap-3">
              <div className="flex flex-wrap items-center justify-start gap-4">
                <div className="text-neutral-400">Jump to:</div>
                <FilterTags
                  selected={filter === "all"}
                  text="All"
                  onClick={() => setFilter("all")}
                />
                <FilterTags
                  selected={filter === "kuration"}
                  onClick={() => setFilter("kuration")}
                  text="Kuration AI enrichment library"
                />
                <FilterTags
                  selected={filter === "custom"}
                  onClick={() => setFilter("custom")}
                  withStars
                  text="Your own filters"
                />
                <FilterTags
                  selected={filter === "history"}
                  onClick={() => setFilter("history")}
                  text="My recipes"
                />
              </div>
            </div>
          </div>

          <div className="w-full p-6">
            {(filter === "all" || filter === "custom") && (
              <CustomFiltersList
                customTools={customTools}
                customToolsLoading={customToolsLoading}
                setShowCreateCustomFilter={setShowCreateCustomFilter}
                setSelectedTool={setSelectedTool}
              />
            )}
            {filter === "history" && (
              <RecipeList setSelectedRecipe={setSelectedRecipe} />
            )}

            {(filter === "all" || filter === "kuration") && (
              <div className="flex flex-col gap-6">
                <div className="flex w-full items-center justify-between gap-4">
                  <div className="text-lg font-normal text-neutral-900">
                    Kuration AI enrichment library ready-made filters
                  </div>
                  <div className="flex min-w-max items-center justify-center gap-2.5 rounded bg-indigo-50 px-3 py-2.5">
                    <div className="text-neutral-900">
                      {tools?.length || 0} filters
                    </div>
                  </div>
                </div>
                <div className="sticky top-0 z-10 mb-4 bg-white py-2">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3">
                    <SearchIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    className="block w-full rounded border p-2 pl-10 ps-10"
                    placeholder="Search tools..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>

                {isLoading && <SimpleSpinner />}
                {(!!error || !Array.isArray(tools)) && (
                  <div onClick={() => retryTools()}>Retry</div>
                )}

                {Object.entries(filteredTools).map(([category, tools]) => (
                  <div key={category}>
                    <h2 className="mb-3 text-xl font-semibold">{category}</h2>
                    <div
                      className={`grid gap-4 ${chatFullScreen ? "grid-cols-3" : "grid-cols-2"}`}
                    >
                      {tools.map((t) => (
                        <div key={t.id} onClick={() => setSelectedTool(t)}>
                          <FilterCard
                            name={t.name}
                            iconUrl={t.icon}
                            description={t.description}
                            cost={t.cost}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
