import React from "react";
import SearchIcon from "src/components/svgs/Search";

interface IndeedJobScrapperFormProps {
  searchUrl: string;
  setSearchUrl: (value: string) => void;
  onSubmit: () => void;
}

const IndeedJobScraperForm: React.FC<IndeedJobScrapperFormProps> = ({
  setSearchUrl,
  searchUrl,
  onSubmit,
}) => {
  return (
    <>
      <div className="flex flex-col gap-3">
        {/* Adjusted gap between label and input */}
        <label className="text-lg">Indeed Search URL</label>
        {/* Increased label font size */}
        <input
          type="text"
          value={searchUrl}
          onChange={(e) => setSearchUrl(e.target.value)}
          placeholder="Paste Indeed search URL here"
          className="w-full rounded border p-3 text-lg"
          // Increased padding and font size for input and set width to full
        />
      </div>

      <div className="mt-4 flex items-center justify-between">
        {/* Added margin at the top for spacing */}
        <span className="text-sm">1 credit per page of results</span>
        <button
          onClick={onSubmit}
          className="flex items-center gap-3 rounded bg-purple p-3 text-lg text-white"
          // Increased padding and font size for button
        >
          <span>Search</span>
          <SearchIcon />
        </button>
      </div>
    </>
  );
};

export default IndeedJobScraperForm;
