import React from "react";

interface LinkedinJobScrapperFormProps {
  jobTitle: string;
  setJobTitle: (value: string) => void;
  location: string;
  setLocation: (value: string) => void;
  publishedAt: string;
  setPublishedAt: (value: string) => void;
  jobType: string;
  setJobType: (value: string) => void;
  experienceLevel: string;
  setExperienceLevel: (value: string) => void;
  onsiteRemote: string;
  setOnsiteRemote: (value: string) => void;
  rows: number;
  setRows: (value: number) => void;
  onSubmit: () => void;
}

const LinkedinJobScrapperForm: React.FC<LinkedinJobScrapperFormProps> = ({
  jobTitle,
  setJobTitle,
  location,
  setLocation,
  publishedAt,
  setPublishedAt,
  jobType,
  setJobType,
  experienceLevel,
  setExperienceLevel,
  onsiteRemote,
  setOnsiteRemote,
  rows,
  setRows,
  onSubmit,
}) => {
  return (
    <div className="flex flex-col gap-6">
      {/* Job Title Input */}
      <div className="flex flex-col gap-2">
        <label htmlFor="jobTitle" className="font-semibold">
          Job Title
          <span className="text-sm text-gray-500">
            {" "}
            (e.g., Software Engineer, AI Engineer)
          </span>
        </label>
        <textarea
          id="jobTitle"
          required
          placeholder="Enter the job title"
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
          className="inline-flex max-h-14 w-full resize-none rounded-md border-2 border-neutral-400 py-3.5 pl-3 pr-11 shadow-md outline-none"
        ></textarea>
      </div>

      {/* Location Input */}
      <div className="flex flex-col gap-2">
        <label htmlFor="location" className="font-semibold">
          Job Location
          <span className="text-sm text-gray-500">
            {" "}
            (e.g., Texas, United States)
          </span>
        </label>
        <textarea
          id="location"
          placeholder="Enter the location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          className="inline-flex max-h-14 w-full resize-none rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-md outline-none hover:border-purple focus:border-purple"
        ></textarea>
      </div>

      {/* Published At Dropdown */}
      <div className="flex flex-col gap-2">
        <label htmlFor="publishedAt" className="font-semibold">
          Published At
        </label>
        <select
          id="publishedAt"
          value={publishedAt}
          onChange={(e) => setPublishedAt(e.target.value)}
          className="inline-flex max-h-14 w-full rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-md outline-none hover:border-purple focus:border-purple"
        >
          <option value="">Select Date Range</option>
          <option value="r2592000">Past Month</option>
          <option value="r604800">Past Week</option>
          <option value="r86400">Past 24 Hours</option>
        </select>
      </div>

      {/* Job Type Dropdown */}
      <div className="flex flex-col gap-2">
        <label htmlFor="jobType" className="font-semibold">
          Job Type
        </label>
        <select
          id="jobType"
          value={jobType}
          onChange={(e) => setJobType(e.target.value)}
          className="inline-flex max-h-14 w-full rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-md outline-none hover:border-purple focus:border-purple"
        >
          <option value="">Select Job Type</option>
          <option value="F">Full Time</option>
          <option value="P">Part Time</option>
          <option value="C">Contract</option>
          <option value="T">Temporary</option>
          <option value="I">Internship</option>
          <option value="V">Volunteer</option>
        </select>
      </div>

      {/* Experience Level Dropdown */}
      <div className="flex flex-col gap-2">
        <label htmlFor="experienceLevel" className="font-semibold">
          Experience Level
          <span className="text-sm text-gray-500">
            {" "}
            (Select an experience level)
          </span>
        </label>
        <select
          id="experienceLevel"
          value={experienceLevel}
          onChange={(e) => setExperienceLevel(e.target.value)}
          className="inline-flex max-h-14 w-full rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-md outline-none hover:border-purple focus:border-purple"
        >
          <option value="">Select Experience Level</option>
          <option value="1">Internship</option>
          <option value="2">Entry Level</option>
          <option value="3">Associate</option>
          <option value="4">Mid-Senior Level</option>
          <option value="5">Director</option>
        </select>
      </div>

      {/* Onsite/Remote Dropdown */}
      <div className="flex flex-col gap-2">
        <label htmlFor="onsiteRemote" className="font-semibold">
          Onsite/Remote
        </label>
        <select
          id="onsiteRemote"
          value={onsiteRemote}
          onChange={(e) => setOnsiteRemote(e.target.value)}
          className="inline-flex max-h-14 w-full rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-md outline-none hover:border-purple focus:border-purple"
        >
          <option value="">Select Work Arrangement</option>
          <option value="1">On-Site</option>
          <option value="2">Remote</option>
          <option value="3">Hybrid</option>
        </select>
      </div>

      {/* Rows Input */}
      <div className="flex flex-col gap-2">
        <label htmlFor="rows" className="font-semibold">
          Rows
        </label>
        <input
          id="rows"
          type="number"
          placeholder="Enter number of rows"
          value={rows}
          onChange={(e) => setRows(Number(e.target.value))}
          className="inline-flex max-h-14 w-full resize-none rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-md outline-none hover:border-purple focus:border-purple"
        ></input>
      </div>

      {/* Submit Button */}
      <div className="mt-4 flex items-center justify-between">
        <span className="text-sm">1 credit per page of results</span>
        <button
          onClick={onSubmit}
          className="flex items-center gap-3 rounded bg-purple p-3 text-lg text-white"
        >
          <span>Search</span>
        </button>
      </div>
    </div>
  );
};

export default LinkedinJobScrapperForm;
