import CloseIcon from "../svgs/CloseIcon";
import { useNavigate } from "react-router-dom";
import backend_services from "src/services/backend_service";
import Spinner from "../elements/Spinner";
import { useAuth } from "src/context/AuthContext";
import { useState } from "react";
import { TableState } from "src/hooks/useChatTable";
import { toast } from "react-toastify";
import SalesNavSearchListForm from "./forms/SalesNavSearchListForm";

interface SalesNavSearchListProps {
  handleClose: () => void;
  initialSearchURL?: string;
  initialLinkedinCookies?: string;
  table_state?: TableState;
  chat_id?: string;
}

const SalesNavSearchList: React.FC<SalesNavSearchListProps> = ({
  initialSearchURL = "",
  initialLinkedinCookies = "",
  handleClose,
  table_state,
  chat_id,
}) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [searchURL, setSearchURL] = useState(initialSearchURL);
  const [sessionCookies, setSessionCookies] = useState(initialLinkedinCookies);
  const navigator = useNavigate();

  async function handleMessageSend() {
    try {
      setLoading(true);

      if (!user) {
        toast.error("User is not authenticated.");
        return;
      }

      if (!searchURL || searchURL.trim() === "") {
        toast.error("Please enter a valid search URL title.");
        return;
      }

      const linkedInSalesUrlPattern =
        /^https?:\/\/(?:www\.)?linkedin\.com\/sales\/search.*$/;

      if (!linkedInSalesUrlPattern.test(searchURL)) {
        toast.error(
          "Please enter a valid LinkedIn Sales Navigator search URL.",
        );
        setLoading(false);
        return;
      }

      if (!sessionCookies || sessionCookies.trim() === "") {
        toast.error("Please enter a session cookie.");
        return;
      }

      const requestBody: any = {
        search_url: searchURL,
        linkedin_cookies: sessionCookies,
      };

      let response;

      if (table_state === TableState.ERROR) {
        // Retry endpoint
        response = await backend_services.fetchProtectedData(
          `/initial_lists/retry/salesNavSearch/${chat_id}`,
          user.getIdToken(),
          undefined,
          "POST",
          requestBody,
        );

        if (response.ok) {
          const result = await response.json();
          toast.success(result.message || "Retry initiated successfully.");
          handleClose(); // Close modal after retry
        } else {
          throw new Error(await response.text());
        }
      } else {
        // Default endpoint
        response = await backend_services.fetchProtectedData(
          `/initial_lists/salesNavSearch`,
          user.getIdToken(),
          undefined,
          "POST",
          requestBody,
        );

        if (response.ok) {
          const result = await response.json();
          if (result?.chat_id) {
            navigator(`/chat/${result.chat_id}`); // Redirect to chat only for default request
            toast.success(result.message || "Request submitted successfully.");
            handleClose(); // Close modal after redirect
          } else {
            toast.error(result.detail || "An error occurred.");
          }
        } else {
          throw new Error(await response.text());
        }
      }
    } catch (error: any) {
      console.error("Error:", error.message || error);
      console.log("erryyaaaa", error.message);
      toast.error(error.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div
      className={`fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform ${loading ? "opacity-95" : null} z-50 w-[700px] rounded-lg bg-white px-8 py-4 shadow-lg`}
    >
      {loading && <Spinner text="Searching..." onWhiteBackground />}
      <div className="flex flex-col gap-5">
        <div className="grid grid-cols-6 items-center">
          <img
            src="/linkedin_icon.png"
            alt="Sales Navigator"
            className="h-12 w-12"
          />
          <div className="col-span-4 col-start-2 justify-self-center text-nowrap text-center text-xl font-bold text-gray-900">
            <h2>Scrape Linkedin Sales Navigator</h2>
          </div>
          <button
            className="col-end-7 justify-self-end text-gray-500 hover:text-gray-700"
            onClick={handleClose}
          >
            <CloseIcon />
          </button>
        </div>
        <hr className="w-full" />
        <SalesNavSearchListForm
          searchURL={searchURL}
          setSearchURL={setSearchURL}
          sessionCookies={sessionCookies}
          setSessionCookies={setSessionCookies}
          onSubmit={handleMessageSend}
        />
      </div>
    </div>
  );
};

export default SalesNavSearchList;
