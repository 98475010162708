import { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  getFirestore,
  where,
  orderBy,
  Timestamp,
} from "firebase/firestore";
import firebase_app from "../firebase/config";
import { CustomTool } from "src/models/tools";
import { useAuth } from "src/context/AuthContext";


const useAllCustomTools = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<CustomTool[]>([]);

  useEffect(() => {
    if (!user) {
      return;
    }
    // Create a reference to the Firestore collection
    const chatsQuery = query(
      collection(getFirestore(firebase_app), `/tools`),
      where("creator_id", "==", user.uid),
      orderBy("timestamp", "desc"),
    );
    // Set up a snapshot listener to listen for changes
    const unsubscribe = onSnapshot(
      chatsQuery,
      (snapshot) => {
        console.log("tools collection had an update.");
        const tools: CustomTool[] = [];
        snapshot.forEach((tool) => {
          const toolData = tool.data() as {
            creator_id: string;
            fields: CustomTool["fields"];
            hidden: boolean;
            timestamp: Timestamp;
          };
          const tool_id = tool.id;
          tools.push({
            id: tool_id,
            fields: {
              default: toolData.fields.default,
              format: toolData.fields.format,
              name: toolData.fields.name,
              objective: toolData.fields.objective,
              outcome: toolData.fields.outcome,
            },
          });
        });
        setLoading(false);
        setData(tools);
      },
      (error) => {
        console.log("ERROR OCCURRED");
        setLoading(false);
        console.error(error);
      },
    );

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [user]);

  return { loading, data };
};

export default useAllCustomTools;
