import { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  getFirestore,
  where,
} from "firebase/firestore";
import firebase_app from "../firebase/config";
import { useAuth } from "src/context/AuthContext";


const useUserSubscriptions = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<{ subId: string }[]>([]);

  useEffect(() => {
    if (!user) {
      return;
    }
    // Create a reference to the Firestore collection
    const chatsQuery = query(
      collection(
        getFirestore(firebase_app),
        `/users/${user.uid}/subscriptions`,
      ),
      where("status", "in", ["active"]),
    );
    // Set up a snapshot listener to listen for changes
    const unsubscribe = onSnapshot(
      chatsQuery,
      (snapshot) => {
        const subs: { subId: string }[] = [];
        snapshot.forEach((sub) => {
          // const subData = sub.data();
          const sub_id = sub.id;
          subs.push({
            subId: sub_id,
          });
        });
        setLoading(false);
        setData(subs);
      },
      (error) => {
        console.log("ERROR OCCURRED in subscriptions hook");
        setLoading(false);
        console.error(error);
      },
    );

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [user]);

  return { loading, data };
};

export default useUserSubscriptions;
