import { useAuth } from "src/context/AuthContext";
import InputBox from "./InputBox";
import { EmailAuthProvider, GoogleAuthProvider, OAuthProvider, User, linkWithCredential, linkWithPopup, reauthenticateWithPopup } from "firebase/auth";
import { toast } from "react-toastify";
import { useState } from "react";



interface LinkProviderProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}

export async function reauthenticate(user: User) {
  if (user.providerData[0].providerId === "google.com") {
    const provider = new GoogleAuthProvider();
    if (user) {
      await reauthenticateWithPopup(user, provider);
    }
  }
  if (user.providerData[0].providerId === "microsoft.com") {
    const provider = new OAuthProvider("microsoft.com");
    if (user) {
      await reauthenticateWithPopup(user, provider);
    }
  }
}

//use to link the sign-in method with third-party (such as Google and Microsoft)
function linkMutipleProvider(providerId: string, user: User) {
  if (providerId === "google.com") {
    const provider = new GoogleAuthProvider();
    if (user) {
      linkWithPopup(user, provider).then((result) => {
        toast.success("Account linking success");
      }).catch((error) => {
        console.log(error);
        toast.error("Account linking Failed. Try again with correct credentials");
      });
    }
  }

  if (providerId === "microsoft.com") {
    const provider = new OAuthProvider('microsoft.com');
    if (user) {
      linkWithPopup(user, provider).then((result) => {
        return toast.success("Account linking success");
      })
        .catch((error) => {
          console.log(error);
          return toast.error("Account linking Failed. Try again with correct credentials");
        });
    }
  }
}

//use to link the sign-in method with Email/Password
async function linkPasswordProvider(email: string, password: string, user: User) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!regex.test(email.toLowerCase()))
    return toast.error("Invaild email");
  if (password.length < 6)
    return toast.error("Invalid Password.\nPassword should be at least 6 characters");

  const credential = EmailAuthProvider.credential(email, password);
  if (user) {
    await reauthenticate(user);
    linkWithCredential(user, credential)
      .then(() => {
        return toast.success("Account linking success");
      }).catch((error) => {
        console.log(error);
        return toast.error("Account linking Failed. Try again with correct credentials");
      });
  }
}

//component when the user hasn't link the sign-in method with some third-party
export const LinkProvider = ({
  text
}: LinkProviderProps) => {
  const { user } = useAuth();
  return (
    <div className="flex justify-between items-center py-6">
      <div className="text-base text-gray-400">Your account haven't link with {text}</div>
      <button
        className="flex h-12 items-center justify-center gap-1.5 rounded-md  bg-primary px-4 text-base leading-[19.2px] text-white hover:bg-purpleHover"
        onClick={() => (user && linkMutipleProvider(text, user))}
      >
        <div>Link</div>
      </button>
    </div>
  );

}

//component when the user hasn't link the sign-in method with Email/Password
export const LinkPassword = () => {
  const { user } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className="grid mb-4 justify-items-stretch">
      <div className="flex justify-between items-center py-2">
        <div className="text-base text-gray-400">Your account haven't link with password</div>
        <button
          className="flex h-12 items-center justify-center gap-1.5 rounded-md  bg-primary px-4 text-base leading-[19.2px] text-white hover:bg-purpleHover"
          onClick={() => (user && linkPasswordProvider(email, password, user))}
        >
          <div>Link</div>
        </button>
      </div>
      <div className="flex justify-between">
        <div className="grid gap-y-1 justify-items-stretch">
          <div className="ml-4">Email</div>
          {user && <InputBox type={"email"} addUserMessage={setEmail} isMessageAllowed={true} />}
        </div>
        <div className="grid gap-y-1 justify-items-stretch">
          <div className="ml-4">Password</div>
          {user && <InputBox type={"password"} addUserMessage={setPassword} isMessageAllowed={true} />}
        </div>
      </div>
    </div>
  );
}

//component when the user has linked the sign-in method with Google
export const GoogleProvider = () => {
  const { user } = useAuth();

  return (
    <div className="flex py-6 gap-x-6">
      <div className="grid gap-y-1 justify-items-stretch">
        <div className="ml-4">Email</div>
        {user && <InputBox isMessageAllowed={false} placeholder={user.providerData.find(user => user.providerId === "google.com")?.email ?? ""} />}
      </div>
    </div>
  )
}

//component when the user has linked the sign-in method with Email/Password
export const PasswordProvider = () => {
  const { user } = useAuth();

  return (
    <div className="flex py-6 gap-x-6">
      <div className="grid gap-y-1 justify-items-stretch">
        <div className="ml-4">Email</div>
        {user && <InputBox type="email" isMessageAllowed={false} placeholder={user.email ?? ""} />}
        <div className="text-xs text-purple justify-self-end">
          <a href="/profile/changeEmail">Change email address</a>
        </div>
      </div>
      <div className="grid gap-y-1 justify-items-stretch">
        <div className="ml-4">Password</div>
        {user && <InputBox type="password" isMessageAllowed={false} startText="123456789" />}
        <div className="text-xs text-purple justify-self-end">
          <a href="/profile/changePassword">Change password</a>
        </div>
      </div>
    </div>
  )
}

//component when the user has linked the sign-in method with Microsoft
export const MicrosoftProvider = () => {
  const { user } = useAuth();

  return (
    <div className="flex py-6 gap-x-6">
      <div className="grid gap-y-1 justify-items-stretch">
        <div className="ml-4">Email</div>
        {user && <InputBox isMessageAllowed={false} placeholder={user.providerData.find(user => user.providerId === "microsoft.com")?.email ?? ""} />}
      </div>
    </div>
  )
}