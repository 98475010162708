import { useAuth } from "../../context/AuthContext";
import BackIcon from "src/components/svgs/Back";
import InputBox from "./InputBox";
import { useState } from "react";
import { RocketIcon } from "src/components/svgs/RocketIcon";
import { reauthenticate } from "./AccountProvider";
import { updateEmail } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";



export default function ChangeEmail() {
  const [newEmail, setNewEmail] = useState("");
  const navigator = useNavigate();
  const { user } = useAuth();

  async function handleChangeEmail() {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!regex.test(newEmail.toLowerCase()))
    return toast.error("Invaild email");
    if (user) {
      await reauthenticate(user);
      updateEmail(user, newEmail)
      .then(() => {
        toast.success("Email Changing success");
      navigator("/login");
      })
      .catch((error) => {
        console.log(error);
        return toast.error("Email Changing Failed. Try again.");
      }) 
    }
  }

  return (
    <div className="grid auto-rows-max gap-y-6 w-[534px]">
      <div className="flex gap-x-4">
        <a href="/profile/account"><BackIcon /></a>
        <div>Back to account</div>
      </div>
      <div className="text-3xl">Change your email address</div>
      <div className="w-[406px]">
        <div>Old email</div>
        <div className="relative">
          {user && (
            <textarea
              readOnly={true}
              placeholder={user.email ?? ""}
              className="peer inline-flex max-h-14 shadow-[2px_2px_20px_0px_#00000014] w-full resize-none items-center justify-center gap-2.5 rounded-md border  py-3.5 pl-3 pr-11  outline-none md:pl-4  placeholder-purple bg-lightpurple"
            ></textarea>)
          }
        </div>
      </div>
      <div className="w-[406px]">
        <div>New email</div>
        {user && <InputBox addUserMessage={setNewEmail} isMessageAllowed={true} />}
      </div>
      <div className="flex justify-end w-[406px]">
        <button
          className="flex h-12 items-center justify-center gap-1.5 rounded-md  bg-primary px-6 text-base leading-[19.2px] text-white hover:bg-purpleHover"
          onClick={handleChangeEmail}
        >
          <RocketIcon />
          <div>Change Email</div>
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}

