import BookIcon from "../../components/svgs/Book";
import HandIcon from "../../components/svgs/Hand";
import QuestionMarkIcon from "../../components/svgs/QuestionMark";
import StarsIcon from "../../components/svgs/Stars";
import TutorialIcon from "../../components/svgs/Tutorials";
import VideoCardBg from "../../components/svgs/VideoCardBg";
import { openInNewTab } from "../../components/utils";
import ChatAction from "./ChatAction";
import { useManualMode } from "src/hooks/useManualMode";

export function NewChatActions() {
  const { toggleManualMode } = useManualMode();
  return (
    <div className="flex flex-col gap-2 md:gap-0">
      <ChatAction
        head="Start manually"
        body="Customize the filters to your taste."
        icon={<HandIcon />}
        iconBg="bg-[#E2FFD4]"
        onClick={() => {
          toggleManualMode();
        }}
      />
    </div>
  );
}

export function HelpChatActions() {
  return (
    <div className="flex flex-col gap-2 md:gap-0">
      <ChatAction
        head="Tutorials"
        body="Learn about all the most important features."
        icon={<TutorialIcon />}
        iconBg="bg-[#FFF2DE]"
        onClick={() => {
          openInNewTab(
            "https://loom.com/share/folder/df6f27dc4648421faa7736caec9f0b65",
          );
        }}
      />
      <ChatAction
        head="FAQ"
        body="The most frequently asked questions."
        icon={<BookIcon />}
        iconBg="bg-[#FFF2DE]"
        onClick={() => {
          openInNewTab(
            "https://chatgpt.com/g/g-WfOen62hk-kuration-ai-assistant",
          );
        }}
      />
      <ChatAction
        head="Help"
        body="We will be happy to help if you have any questions."
        icon={<QuestionMarkIcon />}
        iconBg="bg-[#FFF2DE]"
        onClick={() => {
          openInNewTab("https://wa.me/+85255968334");
        }}
      />
    </div>
  );
}

export function ProductTourCard() {
  return (
    <>
      <h4 className="p-2.5 pl-0 text-base font-normal leading-[19.2px] md:text-xl  md:leading-7 ">
        How to get started:
      </h4>
      <div className="relative mb-6 mt-3 box-border flex min-h-[118px] items-center justify-between rounded-md bg-gradient-to-r from-indigo-500 to-sky-500 p-6 md:mb-12">
        <div className="absolute inset-0 z-0">
          <VideoCardBg className="w-full" />
        </div>
        <div className="z-10 inline-flex flex-col items-start justify-start gap-2">
          <div className="text-lg font-normal leading-[21.6px] text-white md:text-xl md:leading-7">
            Product tour
          </div>
          <div className="text-sm font-normal text-white md:text-2xl md:leading-[33.60px]">
            Delegate your first research tasks!
          </div>
        </div>
        <button
          onClick={() =>
            openInNewTab(
              "https://www.loom.com/share/12bdf3ac5dea4a1a9f027b64452ca18c?sid=2b486e70-864f-4961-bd60-b581c0255a2d",
            )
          }
          className="z-10 flex items-center justify-center rounded-md bg-white px-2.5 py-2 text-neutral-900 outline-none transition-all hover:text-purple focus:text-purple"
        >
          <div className="text-base font-normal leading-[19.2px] md:text-lg ">
            Watch video
          </div>
        </button>
      </div>
    </>
  );
}

export function ChatStarText() {
  return (
    <div className="flex items-center justify-center">
      <div className="inline-block text-center text-sm font-normal text-neutral-400 md:text-xl md:leading-7">
        Chat with your AI agent to clarify your requirements and get a great list!
        <span></span>
        <StarsIcon className="ml-[2px] inline-block h-4 w-4 md:h-[25px] md:w-[23px]" />
      </div>
    </div>
  );
}
