import { PreCuratedLists } from "./preCuratedLists/PreCuratedLists";
import { InitialLists } from "./initialLists/InitialLists";
import items from "./initialLists/item";

export function ListBuilderHome() {
  return (
    <div className="grid h-full grid-rows-[min-content,1fr] gap-16">
      <InitialLists items={items} />
      <PreCuratedLists />
    </div>
  );
}
