import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../svgs/CloseIcon";
import backend_services from "src/services/backend_service";
import Spinner from "../elements/Spinner";
import { toast } from "react-toastify";
import { useAuth } from "src/context/AuthContext";
import LinkedinJobScrapperForm from "./forms/LinkedinJobScrapperForm";
import { TableState } from "src/hooks/useChatTable";

interface LinkedinJobScrapperProps {
  handleClose: () => void;
  numberOfJobs: number;
  initialJobTitle: string;
  initialLocation?: string;
  initialPublishedAt?: string;
  initialJobType?: string;
  initialExperienceLevel?: string;
  initialOnsiteRemote?: string;
  table_state?: TableState;
  chat_id?: string;
}

const LinkedinJobScrapper: React.FC<LinkedinJobScrapperProps> = ({
  handleClose,
  numberOfJobs,
  initialJobTitle = "",
  initialLocation = "",
  initialPublishedAt = "",
  initialJobType = "",
  initialExperienceLevel = "",
  initialOnsiteRemote = "",
  table_state,
  chat_id,
}) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [jobTitle, setJobTitle] = useState(initialJobTitle);
  const [location, setLocation] = useState(initialLocation);
  const [publishedAt, setPublishedAt] = useState(initialPublishedAt);
  const [jobType, setJobType] = useState(initialJobType);
  const [experienceLevel, setExperienceLevel] = useState(
    initialExperienceLevel,
  );
  const [onsiteRemote, setOnsiteRemote] = useState(initialOnsiteRemote);
  const [rows, setRows] = useState(numberOfJobs);
  const navigator = useNavigate();

  async function handleSearch() {
    try {
      setLoading(true);

      if (!user) {
        toast.error("User is not authenticated.");
        return;
      }

      if (!jobTitle || jobTitle.trim() === "") {
        toast.error("Please enter a valid job title.");
        return;
      }

      const requestBody: any = {
        title: jobTitle,
        proxy: {
          useApifyProxy: true,
          apifyProxyGroups: ["RESIDENTIAL"],
        },
      };

      if (location) requestBody.location = location;
      if (publishedAt) requestBody.publishedAt = publishedAt;
      if (jobType) requestBody.contractType = jobType;
      if (experienceLevel) requestBody.experienceLevel = experienceLevel;
      if (onsiteRemote) requestBody.workType = onsiteRemote;
      if (rows) requestBody.rows = rows;

      let response;
      if (table_state === TableState.ERROR) {
        // Retry endpoint
        response = await backend_services.fetchProtectedData(
          `/initial_lists/retry/linkedin_job/${chat_id}`,
          user.getIdToken(),
          undefined,
          "POST",
          requestBody,
        );

        if (response.ok) {
          const result = await response.json();
          toast.success(result.message || "Retry initiated successfully.");
          handleClose(); // Close modal after retry
        } else {
          throw new Error(await response.text());
        }
      } else {
        // Default endpoint
        response = await backend_services.fetchProtectedData(
          `/initial_lists/linkedin_job`,
          user.getIdToken(),
          undefined,
          "POST",
          requestBody,
        );

        if (response.ok) {
          const result = await response.json();
          if (result?.chat_id) {
            navigator(`/chat/${result.chat_id}`); // Redirect to chat only for default request
            toast.success(result.message || "Request submitted successfully.");
            handleClose(); // Close modal after redirect
          } else {
            toast.error(result.detail || "An error occurred.");
          }
        } else {
          throw new Error(await response.text());
        }
      }
    } catch (error: any) {
      console.error("Error:", error.message || error);
      console.log("erryyaaaa", error.message);
      toast.error(error.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative h-[40rem] w-[40rem] overflow-auto rounded-lg bg-white p-8 shadow-lg">
        {loading && <Spinner text="Searching..." onWhiteBackground />}
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-bold">LinkedIn Job Scraper</h2>
          <button onClick={handleClose}>
            <CloseIcon />
          </button>
        </div>
        <hr className="my-4 w-full" />
        <LinkedinJobScrapperForm
          jobTitle={jobTitle}
          setJobTitle={setJobTitle}
          location={location}
          setLocation={setLocation}
          publishedAt={publishedAt}
          setPublishedAt={setPublishedAt}
          jobType={jobType}
          setJobType={setJobType}
          experienceLevel={experienceLevel}
          setExperienceLevel={setExperienceLevel}
          onsiteRemote={onsiteRemote}
          setOnsiteRemote={setOnsiteRemote}
          rows={rows}
          setRows={setRows}
          onSubmit={handleSearch}
        />
      </div>
    </div>
  );
};

export default LinkedinJobScrapper;
