import { useTutorialMode } from "../../hooks/useTutorialMode";
import { HelpChatActions } from "./chatToolbarElements";

const TutorialsSupport = () => {
  const { tutorialOpen } = useTutorialMode();
  return (
    <div className={`mt-6 ${!tutorialOpen ? "hidden md:block" : ""}`}>
      <div className="inline-flex h-12 w-full items-center justify-start gap-2.5 py-2.5 pr-2.5">
        <div className="w-96 text-xl font-normal leading-7 text-neutral-900">
          <div className={`${tutorialOpen ? "hidden md:block" : ""}`}>
            Tutorials and support
          </div>
          <h3
            className={`${
              tutorialOpen ? "block md:hidden" : "hidden"
            } text-base leading-[19.2]`}
          >
            Guidance and assistance:
          </h3>
        </div>
      </div>
      <HelpChatActions />
    </div>
  );
};

export default TutorialsSupport;
